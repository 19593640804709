import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import Map from "../Map/Map";
import grapexpress from "../../../../assets/img/grapexpress.png";
export default function CreateOrder({ id, status }) {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Tạo đơn hàng">
        <Button
          type="primary"
          disabled={
            status === "ALLOCATING" ||
            status === "PENDING_PICKUP" ||
            status === "PICKING_UP" ||
            status === "PENDING_DROP_OFF" ||
            status === "IN_DELIVERY" ||
            status === "COMPLETED" 
          }
          onClick={showModal}
          icon={<PlusCircleOutlined />}
        ></Button>
      </Tooltip>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color: "#389e0d", marginRight: 10 }}>
              Tạo đơn hàng
            </span>{" "}
            <img style={{ width: "150px" }} src={grapexpress} alt="" />
          </div>
        }
        open={open}
        footer={null}
        width={680}
        onCancel={handleCancel}
        centered
      >
        <Map id={id} />
      </Modal>
    </>
  );
}
