import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { http } from '../../utils/config';
import { message } from "antd";

export const GetPriceViettelPost = createAsyncThunk(
    'ViettelPost/GetPriceViettelPost',
    async (data) => {
      const response = await http.post(`/get-price-viettelpost`, data,);
      if (response.data.data.data.length === 0) {
        message.error("Giá không áp dụng cho hành trình này!");
      }
      return response.data.data;
    }
  );

const getPriceViettelPost = createSlice({
  name: 'GetPriceViettelPost',
  initialState: {
    isFetching: false,
    servicelistxtra: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetPriceViettelPost.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(GetPriceViettelPost.fulfilled, (state, action) => {
        state.servicelistxtra = action.payload.data || [];
        state.isFetching = false;
      })
      .addCase(GetPriceViettelPost.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.error.message;
      });
  },
});

export default getPriceViettelPost.reducer;
