import React from "react";
import { useLoadScript } from "@react-google-maps/api";
import { Spin } from "antd";
import { libraries } from "../../../../utils/config";
import FormCreate from "../Form/FormCreate";
export default function Map({id}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_GOOGLE,
    libraries: libraries,
  });
  return (
    <div id="boxmap" className="formGrap" style={{ padding: "20px" }}>
      {!isLoaded ? (
        <div style={{ textAlign: "center", margin: "20px" }}>
          <Spin size="large" tip="Đang tải...">
            <div>Content</div>
          </Spin>
        </div>
      ) : (
        <FormCreate id={id} />
      )}
    </div>
  );
}
