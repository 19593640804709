import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    orderTiktok: null,
    isLoadSync: false
}
export const createSyncTiktok = createAsyncThunk(
    'TikTok/createSyncTiktok',
    async (data) => {
        // console.log(data)
        const response = await http.post(
            `/create-product`, data
        );
        return response.data.data;
    }
);
const syncTikTok = createSlice({
    name: "syncTikTok",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createSyncTiktok.pending, (state) => {
                state.isLoadSync = true;
            })
            .addCase(createSyncTiktok.fulfilled, (state, action) => {
                state.orderTiktok = action.payload
                state.isLoadSync = false;
            })
            .addCase(createSyncTiktok.rejected, (state, action) => {
                state.isLoadSync = false;
            })
    },

});

// export const { } = syncTikTok.actions

export default syncTikTok.reducer