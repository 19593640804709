import { AutoComplete, message } from "antd";
import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
export default function Search(props) {
  const {
    ready,
    value,
    clearSuggestions,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      locationRestriction: {
        north: 23.393395, // Vĩ độ bắc của Việt Nam
        south: 8.180375, // Vĩ độ nam của Việt Nam
        east: 109.464211, // Kinh độ đông của Việt Nam
        west: 102.144546, // Kinh độ tây của Việt Nam
      },
    },
  });
  const options = data.map((address) => ({
    value: address.description,
  }));

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const search = { lat, lng };
      const { formatted_address } = results[0];
      props.onSearchChange({ search, formatted_address });
    } catch (error) {
      message.error(error);
    }
  };
  return (
    <AutoComplete
      style={{ width: "100%" }}
      placeholder="Địa chỉ người nhận"
      options={options}
      value={value}
      onChange={setValue}
      onSelect={handleSelect}
      disabled={!ready}
      allowClear
      onClear={() => props.onSearchChange(null)}
    />
  );
}
