import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    status: null,
    isLoadStatus: false,
    isLoadPrice: false,
    isLoadStock: false
}
export const statusTikTok = createAsyncThunk(
    'TikTok/statusTikTok',
    async (data) => {
        // console.log(data)
        const response = await http.post(
            `/update-status-tiktok`, data
        );
        // console.log(response)
        return response.data.data;
    }
);
export const updatePrice = createAsyncThunk(
    'TikTok/updatePrice',
    async (data) => {
        // console.log(data)
        const response = await http.post(
            `/update-price-tiktok`, data
        );
        // console.log(response)
        return response.data.data;
    }
);
export const updateStock = createAsyncThunk(
    'TikTok/updateStock',
    async (data) => {
        // console.log(data)
        const response = await http.post(
            `/update-stock-tiktok`, data
        );
        // console.log(response)
        return response.data.data;
    }
);
const updateStatusTiktok = createSlice({
    name: "updateStatusTiktok",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(statusTikTok.pending, (state) => {
                state.isLoadStatus = true;
            })
            .addCase(statusTikTok.fulfilled, (state, action) => {
                state.status = action.payload
                state.isLoadStatus = false;
            })
            .addCase(statusTikTok.rejected, (state, action) => {
                state.isLoadStatus = false;
            })
            //updatePrice
            .addCase(updatePrice.pending, (state) => {
                state.isLoadPrice = true;
            })
            .addCase(updatePrice.fulfilled, (state, action) => {
                state.isLoadPrice = false;
            })
            .addCase(updatePrice.rejected, (state, action) => {
                state.isLoadPrice = false;
            })
            //UpdateStock
            .addCase(updateStock.pending, (state) => {
                state.isLoadStock = true;
            })
            .addCase(updateStock.fulfilled, (state) => {
                state.isLoadStock = false;
            })
            .addCase(updateStock.rejected, (state) => {
                state.isLoadStock = false;
            })
    },

});

export const { } = updateStatusTiktok.actions

export default updateStatusTiktok.reducer