import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import Map from "../Map/Map";
import ahamove from "../../../../assets/img/Logo-ahamove.svg";
export default function CreateOrder({ id, status, cart_id }) {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Tạo đơn hàng">
        <Button
          type="primary"
          disabled={
            status === "IDLE" ||
            status === "ASSIGNING" ||
            status === "ACCEPTED" ||
            status === "IN PROCESS" ||
            status === "COMPLETED"
          }
          onClick={showModal}
          icon={<PlusCircleOutlined />}
        ></Button>
      </Tooltip>
      <Modal
        title={
          <div className="popup_ahamove_title">
            Tạo đơn hàng<img style={{ width: "200px" }} src={ahamove} alt="tạo đơn hàng ahamove" />
          </div>
        }
        className="popup_ahamove"
        open={open}
        footer={null}
        width={680}
        onCancel={handleCancel}
        centered
      >
        <Map id={id} cart_id={cart_id} />
      </Modal>
    </>
  );
}
