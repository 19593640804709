import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESSTOKEN, http, settings } from "../../utils/config";

const initialState = {
  token: null,
  isLoading: false,
};

export const conect = createAsyncThunk("connectSendo/conect", async (data) => {
  try {
    const response = await http.post("/connect-sendo", data);
    return response.data;
  } catch (error) {
    // Xử lý lỗi
    throw new Error("Có lỗi xảy ra trong quá trình kết nối");
  }
});
const connectSendo = createSlice({
  name: "connectSendo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(conect.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(conect.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload;
        settings.setCookie(
          ACCESSTOKEN,
          action.payload.data.access_token,
          1 / 4
        );
      })
      .addCase(conect.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

// export const {} = connectSendo.actions;

export default connectSendo.reducer;
