import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { https } from "../../utils/config";

const initialState = {
  provinceAllVnp: null,
  districtAllVnp: null,
  wardAllVnp: null,
  optionProvinceAllVnp: null,
  optionDistrictAllVnp: null,
  optionWardAllVnp: null,
  isloadProvinceVnp: false,
  isloadDistrictVnp: false,
  isloadWardVnp: false,
};

export const getProvinceVnp = createAsyncThunk(
  "VietnamPost/getProvinceVnp",
  async () => {
    const response = await https.get("/province-vnpost");
    return response.data.data;
  }
);

export const getDistrictVnp = createAsyncThunk(
  "VietnamPost/getDistrictVnp",
  async (id) => {
    const response = await https.get(`/district-vnpost/${id}`);
    return response.data.data;
  }
);

export const getWardVnp = createAsyncThunk(
  "VietnamPost/getWardVnp",
  async (id) => {
    const response = await https.get(`/ward-vnpost/${id}`);
    return response.data.data;
  }
);

const getAddress = createSlice({
  name: "getAddress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // lấy tỉnh/tp
      .addCase(getProvinceVnp.pending, (state) => {
        state.isloadProvinceVnp = true;
      })
      .addCase(getProvinceVnp.fulfilled, (state, action) => {
        state.provinceAllVnp = action.payload;
        state.optionProvinceAllVnp = action?.payload?.map((data) => ({
          value: data.province_vnpost_id,
          label: data.name,
        }));
        state.isloadProvinceVnp = false;
      })
      .addCase(getProvinceVnp.rejected, (state, action) => {
        state.isloadProvinceVnp = false;
        state.error = action.error.message;
      })
      // lấy quận/huyện
      .addCase(getDistrictVnp.pending, (state) => {
        state.isloadDistrictVnp = true;
      })
      .addCase(getDistrictVnp.fulfilled, (state, action) => {
        state.districtAllVnp = action.payload;
        state.optionDistrictAllVnp = action?.payload?.map((data) => ({
          value: data.district_vnp_id,
          label: data.name,
        }));
        state.isloadDistrictVnp = false;
      })
      .addCase(getDistrictVnp.rejected, (state, action) => {
        state.isloadDistrictVnp = false;
        state.error = action.error.message;
      })
      // lấy xã/phường
      .addCase(getWardVnp.pending, (state) => {
        state.isloadWardVnp = true;
      })
      .addCase(getWardVnp.fulfilled, (state, action) => {
        state.wardAllVnp = action.payload;
        state.optionWardAllVnp = action?.payload?.map((data) => ({
          value: data.ward_vnp_id,
          label: data.name,
        }));
        state.isloadWardVnp = false;
      })
      .addCase(getWardVnp.rejected, (state, action) => {
        state.isloadWardVnp = false;
        state.error = action.error.message;
      });
  },
});

export const {} = getAddress.actions;

export default getAddress.reducer;
