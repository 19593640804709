import { DisconnectOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import React from "react";
import { persistor } from "../../../../redux/configStore";
import { settings } from "../../../../utils/config";

export default function LogoutGrap() {
  const Logout = () => {
    persistor.pause();
    persistor.flush().then(() => {
      settings.delete_cookie("accessTokenGrap");
      window.location.href = "/vanchuyen";
      return persistor.purge();
    });
  };
  return (
    <div>
      <span style={{ color: "#7790b6", fontSize: "12px" }}>GrapExpress</span>
      <RightOutlined style={{ margin: "0 20px", fontSize: 10 }} />
      <span style={{ color: "#7790b6", fontSize: "12px" }}>Cấu hình</span>
      <Divider />
      <Button
        type="primary"
        onClick={Logout}
        danger
        icon={<DisconnectOutlined />}
      >
        Gỡ kết nối
      </Button>
    </div>
  );
}
