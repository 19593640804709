import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    isLoadCreate: false,
    createGhn: null
}
export const createOderGHN = createAsyncThunk(
    'Giaohangnhanh/createGhn',
    async (data) => {
        const response = await http.post(
            `/create-order`, data
        );
        return response.data.data;
    }
);
const CreateOrderGhn = createSlice({
    name: "CreateOrderGhn",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createOderGHN.pending, (state) => {
                state.isLoadCreate = true;
            })
            .addCase(createOderGHN.fulfilled, (state, action) => {
                state.isLoadCreate = false;
                state.createGhn = action.payload;
            })
            .addCase(createOderGHN.rejected, (state, action) => {
                state.isLoadCreate = false;
                // state.err = action.payload.message;

            })


    },

});

export const { } = CreateOrderGhn.actions

export default CreateOrderGhn.reducer