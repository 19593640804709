import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    listOrder: null,
    isLoadOrder: false
}
export const getListOrder = createAsyncThunk(
    'Giaohangnhanh/getListOrder',
    async (data) => {
        const response = await http.get(
            `/list-cart?idw=${data.idw}&page=${data.page}`,
        );
        
        return response.data;
    }
);

export const updateStatus = createAsyncThunk(
    'Giaohangnhanh/updateStatus',
    async (data) => {
        const response = await http.post(
            `/ready-to-pick`, data
        );
        return response.data;
    }
);
export const cancelOrderGhn = createAsyncThunk(
    'Giaohangnhanh/cancelOrderGhn',
    async (data) => {
        const response = await http.post(
            `ghn/order-cancel`, data
        );
        return response.data.data;
    }
);
const listProductOrder = createSlice({
    name: "listProductOrder",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //Api gọi lấy gói dịch vụ
            .addCase(getListOrder.pending, (state) => {
                state.isLoadOrder = true;
            })
            .addCase(getListOrder.fulfilled, (state, action) => {
                state.listOrder = action.payload
                state.isLoadOrder = false;
            })
            .addCase(getListOrder.rejected, (state, action) => {
                state.isLoadOrder = false;
                state.error = action.error.message;
            })

    },
});

export const { } = listProductOrder.actions

export default listProductOrder.reducer