import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { https } from "../../utils/config";

const initialState = {
  listOrdervnp: null,
  isLoadOrderVnp: false,
};

export const getlistOrderVnp = createAsyncThunk(
  "VietnamPost/getlistOrderVnp",
  async (idw) => {
    const response = await https.get(`/list-cart-vnpost?idw=${idw}`);
    return response.data.data;
  }
);

const ListOrderVnp = createSlice({
  name: "listOrderVnp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getlistOrderVnp.pending, (state) => {
        state.isLoadOrderVnp = true;
      })
      .addCase(getlistOrderVnp.fulfilled, (state, action) => {
        state.listOrdervnp = action.payload;
        state.isLoadOrderVnp = false;
      })
      .addCase(getlistOrderVnp.rejected, (state, action) => {
        state.isLoadOrderVnp = false;
        state.error = action.error.message;
      });
  },
});

export const {} = ListOrderVnp.actions;

export default ListOrderVnp.reducer;
