import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESSTOKEN_LAZADA, httplazada, settings } from "../../utils/config";

const initialState = {
  categoryAll: null,
  options: null,
  brandsAll: null,
  optionsBrand: null,
  isLoadCategory: false,
  isLoadBrands: false,
};

export const getCategoryLazada = createAsyncThunk(
  "Lazada/getCategoryLazada",
  async () => {
    const response = await httplazada.get("/get-category-tree-lazada");
    return response.data.data.data;
  }
);

export const getBrandsLazada = createAsyncThunk(
  "Lazada/getBrandsLazada",
  async () => {
    const response = await httplazada.get("/category/brands/query");
    return response.data.data.data.module;
  }
);

const UrlgetCategoryLazada = createSlice({
  name: "UrlgetCategoryLazada",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryLazada.pending, (state) => {
        state.isLoadCategory = true;
      })
      .addCase(getCategoryLazada.fulfilled, (state, action) => {
        state.categoryAll = action?.payload;
        state.options = action?.payload?.map((category) => ({
          value: category.category_id,
          label: category.name,
        }));
        state.isLoadCategory = false;
      })
      .addCase(getCategoryLazada.rejected, (state, action) => {
        state.isLoadCategory = false;
      })
      .addCase(getBrandsLazada.pending, (state) => {
        state.isLoadBrands = true;
      })
      .addCase(getBrandsLazada.fulfilled, (state, action) => {
        state.brandsAll = action?.payload;
        state.optionsBrand = action?.payload?.map((brand) => ({
          value: brand.brand_id,
          label: brand.name,
        }));
        state.isLoadBrands = false;
      })
      .addCase(getBrandsLazada.rejected, (state, action) => {
        state.isLoadBrands = false;
      });
  },
});

export default UrlgetCategoryLazada.reducer;
