import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http, settings } from '../../utils/config';

const initialState = {
    options : null,
    isLoadCategory: false,
    brand:null,
    isLoadBrand:false
}
export const getCategoryShopee = createAsyncThunk(
    'Shopee/getCategoryShopee',
    async (accessToken) => {
        const response = await http.get(
            `/get-category-shopee?access_token=${accessToken}&shop_id=${settings.getCookie("shopId")}`
        );
        // console.log(response.data.data);
        return response.data.data;  
    }
);
export const getBrandShopee = createAsyncThunk(
    'Shopee/getBrandShopee',
    async ({ Token_Brand, category_id }) => {
      const response = await http.get(
        `/get-brand-shopee?shop_id=${settings.getCookie("shopId")}&access_token=${Token_Brand}&category_id=${category_id}&offset=0&page_size=20&status=1`
      );
      const brandData = response.data.data?.response?.brand_list || [];
      const transformedBrandData = brandData.map((brand) => ({
        value: brand.brand_id,
        label: brand.display_brand_name,
      }));
      return transformedBrandData;
    }
  );
  
  
const getCategory = createSlice({
    name: "getCategory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategoryShopee.pending, (state) => {
                state.isLoadCategory = true;
            })
            .addCase(getCategoryShopee.fulfilled, (state, action) => {
                state.options = action.payload
                state.isLoadCategory = false;
            })
            .addCase(getCategoryShopee.rejected, (state, action) => {
                state.isLoadCategory = false;
            })
            //get brand
            .addCase(getBrandShopee.pending, (state) => {
                state.isLoadBrand = true;
            })
            .addCase(getBrandShopee.fulfilled, (state, action) => {
                state.brand = action.payload
                state.isLoadBrand = false;
            })
            .addCase(getBrandShopee.rejected, (state, action) => {
                state.isLoadBrand = false;
            })


    },
});

// export const { } = getCategory.actions

export default getCategory.reducer