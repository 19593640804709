import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    data: null,
    isLoading: false
}
export const freeShip = createAsyncThunk("GrapExpress/freeShip", async (data) => {
    const response = await http.post("/deliveries-quotes-grab", data);
    
    if (response.data.data.arg && response.data.data.arg.includes("cannot support multiple city deliveries")) {
         throw new Error("Địa chỉ không được hỗ trợ vận chuyển");
    }
    if (response.data.data.arg && response.data.data.arg.includes("Code: 107. Reason: Package over size limit. Height limit: 50")) {
        throw new Error("Giới hạn chiều cao: 50");
   }
    return response.data.data;
});

const FreeShipGrap = createSlice({
    name: "FreeShipGrap",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(freeShip.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(freeShip.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(freeShip.rejected, (state, action) => {
                state.isLoading = false;
                // state.err = action.payload.message;

            })
    },
});

// export const { } = FreeShipGrap.actions

export default FreeShipGrap.reducer