import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    isloadList: false,
    data: null
}
export const getPickList = createAsyncThunk(
    'Giaohangnhanh/getPickList',
    async () => {
        const response = await http.get(
            `/shift-date`,
        );
        return response.data.data.data;
    }
);
const getPickUpList = createSlice({
    name: "getPickUpList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPickList.pending, (state) => {
                state.isloadList = true;
            })
            .addCase(getPickList.fulfilled, (state, action) => {
                state.data = action?.payload?.map((picklist) => ({
                    value: picklist.id,
                    label: picklist.title,
                }));
                state.isloadList = false;
            })
            .addCase(getPickList.rejected, (state, action) => {
                state.isloadList = false;
                state.error = action.error.message;
            })

    },
});

export const { } = getPickUpList.actions

export default getPickUpList.reducer