import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ACCESSTOKEN_LAZADA, httplazada, settings } from "../../utils/config";

const initialState = {
  token: null,
  isLoading: false,
};

export const conect = createAsyncThunk(
  "connectLazada/conect",
  async (dataInput) => {
    console.log(dataInput);
    try {
      const data = {
        status: 200,
        message: "Kết nối thành công",
        data: {
          access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
          idw: 19205,
        },
      };
      const nation = "Singapore";
      const email = "thanhdo2807@gmail.com";
      const password = "123456";
      if (
        nation == dataInput.nation &&
        email == dataInput.email &&
        password == dataInput.password
      ) {
        return data;
      }
    } catch (error) {
      // Xử lý lỗi
      throw new Error("Có lỗi xảy ra trong quá trình kết nối");
    }
  }
);

const connectLazada = createSlice({
  name: "connectLazada",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(conect.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(conect.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload;
        settings.setCookie(
          ACCESSTOKEN_LAZADA,
          action.payload.data.access_token,
          1 / 4
        );
        settings.setCookie("idw_lazada", action.payload.data.idw, 1 / 4);
      })
      .addCase(conect.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default connectLazada.reducer;
