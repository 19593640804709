import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { settings } from "../utils/config";
import { store } from "../redux/configStore";
import { refreshTokenLogin } from "../redux/Login/Login";
export default function PrivativeRouterLogin() {
  const userToken = settings.getCookie("user_token");
  const refreshUserToken = settings.getCookie("refresh_tokenUser");
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchNewToken() {
      try {
        // Gửi yêu cầu lấy token mới
        const response = await store.dispatch(
          refreshTokenLogin({
            refresh_token: refreshUserToken,
          })
        );
        // console.log(response);
        // Cập nhật access token mới
        const newUserToken = response.payload.access_token;
        // Cập nhật trong cookie
        settings.setCookie("user_token", newUserToken, 1);
      } catch (error) {
        navigate("/login");
      }
    }
    if (userToken && refreshUserToken) {
      fetchNewToken();
    }
    // Thiết lập interval để gọi lại fetchNewToken() sau mỗi 6 giờ
    const interval = setInterval(fetchNewToken, 6 * 60 * 60 * 1000);
    // Xóa interval khi component unmount
    return () => clearInterval(interval);
  }, [navigate, userToken, refreshUserToken]);
  return settings?.getCookie("user_token") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}
