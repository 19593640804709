import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Tooltip, message } from "antd";
import { items } from "./Silder/menu";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { settings } from "../utils/config";
import HeaderBota from "./Header/HeaderBota";
import { useSelector } from "react-redux";
const { Header, Sider, Content } = Layout;
const headerStyle = {
  color: "#fff",
  height: 64,
  lineHeight: "64px",
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: " 0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
  width: "100%",
  zIndex: 1000,
};
export default function Body() {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.persistedReducer.Login);
  useEffect(() => {
    if (!settings?.getCookie("user_token")) {
      navigate("/login");
      message.error("bạn đã hết phiên đăng nhập, xin hãy đăng nhập lại");
    }
  }, []);
  const [collapsed, setCollapsed] = useState(false);
  const onClick = (e) => {
    navigate(e.key);
  };
  return (
    <div className="body">
      <Layout style={{ height: "100vh" }}>
        <Header style={headerStyle}>
          <HeaderBota />
        </Header>
        <Layout style={{ backgroundColor: "white", flexDirection: "row" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            style={{
              backgroundColor: "#FFFFFF",
              overflowY: "auto",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            {!collapsed ? (
              <NavLink target="_blank" to={`https://${userData?.domain_web}`}>
                <Button
                  type="primary"
                  style={{
                    borderRadius: 16,
                    fontWeight: 700,
                    margin: 10,
                  }}
                >
                  Xem trang
                </Button>
              </NavLink>
            ) : (
              <NavLink target="_blank" to={`https://${userData?.domain_web}`}>
                <div
                  style={{
                    textAlign: "center",
                    borderRadius: 58,
                    color: "#fff",
                    backgroundColor: "#1677ff",
                    marginLeft: "5px",
                    fontSize: 20,
                    height: 33,
                    lineHeight: 1.5,
                    margin: 10,
                  }}
                >
                  <Tooltip title="Xem trang web" placement="right">
                    <QrcodeOutlined />
                  </Tooltip>
                </div>
              </NavLink>
            )}
            <div className="demo-logo-vertical" />
            <Menu
              onClick={onClick}
              style={{ backgroundColor: "#FFFFFF", borderInlineEnd: "none" }}
              items={items}
              mode="inline"
              inlineIndent={10}
            />
          </Sider>
          <Content
            style={{
              padding: 10,
              background: "#f6f6f6",
              overflowY: "auto",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
