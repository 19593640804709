import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http, settings } from '../../utils/config';

const initialState = {
    accessToken: null,
    isLoading: false
}
export const loginGrap = createAsyncThunk("GrapExpress/loginGrap", async () => {
    const response = await http.get("/get-token-grab");
    return response.data;
});
const LoginGrap = createSlice({
    name: "LoginGrap",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginGrap.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginGrap.fulfilled, (state, action) => {
                state.accessToken = action.payload
                settings.setCookie("accessTokenGrap", action.payload.data.access_token,7)
                state.isLoading = false;
            })
            .addCase(loginGrap.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    },

});

// export const {} = LoginGrap.actions

export default LoginGrap.reducer