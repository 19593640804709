import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { http } from '../../utils/config';

const initialState = {
    isFetching: false,
    warehouses: [],
    error: null,
};

export const getWarehouse = createAsyncThunk(
  'ViettelPost/getWarehouse',
  async () => {
    try {
      const response = await http.get('/viettelpost-list-inventory');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching warehouse data:', error);
      throw error;
    }
  }
);

const warehouseSlice = createSlice({
    name: 'getWarehouse',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
    builder
    .addCase(getWarehouse.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getWarehouse.fulfilled, (state, action) => {
        state.warehouses = action.payload;
        state.isFetching = false;
      })
      .addCase(getWarehouse.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.error.message;
      });
  },
});

export default warehouseSlice.reducer;
