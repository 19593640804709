import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    iswarehouse: false,
    data: null
}

export const listWareHouse = createAsyncThunk(
    'Giaohangnhanh/listWareHouse',
    async () => {
        const response = await http.get(
            `/list-shop`
        );
        return response.data.data.shops;
    }
);

const warehouseList = createSlice({
    name: "warehouseList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //Get api category cấp 1
            .addCase(listWareHouse.pending, (state) => {
                state.iswarehouse = true;
            })
            .addCase(listWareHouse.fulfilled, (state, action) => {
                state.data = action?.payload?.map((category) => ({
                    value: category._id,
                    label: category.name,
                    from_district: category.district_id,
                    from_wardcode: category.ward_code
                }));
                state.iswarehouse = false;
            })
            .addCase(listWareHouse.rejected, (state, action) => {
                state.iswarehouse = false;
                state.error = action.error.message;
            })

    },
});

// export const { } = warehouseList.actions

export default warehouseList.reducer

