import {
    Button,
    Col,
    Divider,
    Image,
    Input,
    Row,
    Space,
    Table,
    Tooltip,
    message,
  } from "antd";
  import React, { useEffect, useRef, useState } from "react";
  import formatDateTime from "../../../utils/dateTime";
  import { useDispatch, useSelector } from "react-redux";
  import {
    EyeInvisibleTwoTone,
    EyeTwoTone,
    RightOutlined,
    SearchOutlined,
    SyncOutlined
  } from "@ant-design/icons";
  import { store } from "../../../redux/configStore";
  import { getListOrder } from "../../../redux/Giaohangnhanh/listProductOrder";
  import { settings } from "../../../utils/config";
  import CreateOrder from "./CreateOrderViettelpost/CreateOrderViettelpost";
  import { useNavigate } from "react-router-dom";
  import { LoadDataV2 } from "../../../redux/Login/Login";
  import { setHasFetchedData } from "../../../redux/ViettelPost/CreateOrderViettelPost";
  export default function HomeViettelPost() {
    const [searchText, setSearchText] = useState("");
    const { listOrder, isLoadOrder } = useSelector(
      (state) => state.persistedReducer.listProductOrder
    );
    const dispatch = useDispatch();
    const { hasFetchedData } = useSelector(
      (state) => state.persistedReducer.CreateOrderGrap
    );
    const isSSL = window.location.protocol === "https:";
    const navigate = useNavigate();
    const searchRef = useRef();
    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = settings.getCookie("vietel_post_token");
          if (isTokenExpired(token)) {
            message.warning("Token Viettel Post đã hết hạn. Vui lòng đăng nhập lại.");
            settings.delete_cookie("vietel_post_token");
            window.location.href = "/vanchuyen";
            return;
          }
          await store.dispatch(getListOrder({ idw : settings.getCookie("user_idw"), page:'vtp'}));
          dispatch(setHasFetchedData(true));
        } catch (error) {
          message.error("Có lỗi xảy ra không tồn tại dữ liệu ");
        }
      };
  
      if (!hasFetchedData) {
        fetchData();
      }
    }, [hasFetchedData, dispatch]);

    const isTokenExpired = (token) => {
      const tokenData = parseToken(token);
      if (!tokenData || !tokenData.exp) {
        return true;
      }
    
      const expirationTime = tokenData.exp * 1000;
      const currentTime = Date.now();
    
      return currentTime >= expirationTime;
    };
    const parseToken = (token) => {
      try {
        const [, payloadBase64] = token.split('.');
        const decodedPayload = JSON.parse(atob(payloadBase64));
        return decodedPayload;
      } catch (error) {
        console.error('Error parsing token:', error);
        return null;
      }
    };

    const [filteredData, setFilteredData] = useState();
    useEffect(() => {
      const data = listOrder?.map((items, index) => {
        const itemsData = items.productofcart.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          price: parseFloat(item.price),
          image: item.image,
        }));
        const formattedDateTime = formatDateTime(items.created_at);
        let statusViettel = "";
        let statusClassName = '';
        if (items.status_viettelpost === "new") {
          statusViettel = "Đơn hàng mới";
          statusClassName = "vtp-label-new";
        } else if (items.status_viettelpost === "0") {
          statusViettel = "Đang xử lý";
          statusClassName = "vtp-label-status";
        } else if (items.status_viettelpost === "-100") {
          statusViettel = "Chưa duyệt";
          statusClassName = "vtp-label-chua-duyet";
        } else if (items.status_viettelpost === "-108") {
          statusViettel = "Gửi tại bưu cục";
          statusClassName = "vtp-label-da-duyet";
        } else if (items.status_viettelpost === "-109") {
          statusViettel = "Đã gửi tại điểm thu gom";
          statusClassName = "vtp-label-da-gui-tai-cua-hang";
        } else if (items.status_viettelpost === "-110") {
          statusViettel = "Đang bàn giao qua bưu cục";
          statusClassName = "vtp-label-da-gui-tai-cua-hang";
        } else if (items.status_viettelpost === "100") {
          statusViettel = "Đã tiếp nhận";
          statusClassName = "vtp-label-da-duyet"
        } else if (items.status_viettelpost === "101") {
          statusViettel = "ViettelPost yêu cầu hủy đơn hàng";
          statusClassName = "vtp-label-da-huy"
        } else if (items.status_viettelpost === "102") {
          statusViettel = "Đơn hàng chờ xử lý";
          statusClassName = "vtp-label-da-duyet"
        } else if (items.status_viettelpost === "103") {
          statusViettel = "Giao cho bưu cục";
          statusClassName = "vtp-label-da-duyet"
        } else if (items.status_viettelpost === "104") {
          statusViettel = "Giao cho Bưu tá đi nhận";
          statusClassName = "vtp-label-da-duyet"
        } else if (items.status_viettelpost === "105") {
          statusViettel = "Buu Tá đã nhận hàng";
          statusClassName = "vtp-label-da-lay-hang";
        } else if (items.status_viettelpost === "106") {
          statusViettel = "Đối tác yêu cầu lấy lại hàng";
          statusClassName = "vtp-label-da-lay-hang";
        } else if (items.status_viettelpost === "107") {
          statusViettel = "Đã huỷ";
          statusClassName = "vtp-label-da-huy"
        } else if (items.status_viettelpost === "200") {
          statusViettel = "Nhận từ bưu tá - Bưu cục gốc";
          statusClassName = "vtp-label-dang-van-chuyen";
        } else if (items.status_viettelpost === "201") {
          statusViettel = "Hủy nhập phiếu gửi";
          statusClassName = "vtp-label-da-huy"
        } else if (items.status_viettelpost === "202") {
          statusViettel = "Sửa phiếu gửi";
          statusClassName = "vtp-label-dang-van-chuyen";
        } else if (items.status_viettelpost === "300") {
          statusViettel = "Đóng file gửi";
          statusClassName = "vtp-label-dang-van-chuyen";;
        } else if (items.status_viettelpost === "301") {
          statusViettel = "Ðóng túi gói'";
          statusClassName = "vtp-label-chua-in";
        } else if (items.status_viettelpost === "302") {
          statusViettel = "Đóng chuyến thư";
          statusClassName = "vtp-label-chua-in";
        } else if (items.status_viettelpost === "303") {
          statusViettel = "Đóng tuyến xe";
          statusClassName = "vtp-label-chua-in";
        } else if (items.status_viettelpost === "400") {
          statusViettel = "Nhận bảng kê đến";
          statusClassName = "vtp-label-dang-van-chuyen";
        } else if (items.status_viettelpost === "401") {
          statusViettel = "Nhận Túi gói";
          statusClassName = "vtp-label-chua-in";
        } else if (items.status_viettelpost === "402") {
          statusViettel = "Nhận chuyến thư";
          statusClassName = "vtp-label-chua-in";
        } else if (items.status_viettelpost === "403") {
          statusViettel = "Nhận chuyến xe";
          statusClassName = "vtp-label-chua-in";
        } else if (items.status_viettelpost === "500") {
          statusViettel = "Giao bưu tá đi phát";
          statusClassName = "vtp-label-dang-giao-hang";
        } else if (items.status_viettelpost === "501") {
          statusViettel = "Thành công - Phát thành công";
          statusClassName = "vtp-label-dang-van-chuyen";
        } else if (items.status_viettelpost === "502") {
          statusViettel = "Chuyển hoàn bưu cục gốc";
          statusClassName = "vtp-label-duyet-hoan";
        } else if (items.status_viettelpost === "503") {
          statusViettel = "Shop huỷ lấy";
          statusClassName = "vtp-label-da-huy"
        } else if (items.status_viettelpost === "504") {
          statusViettel = "Thành công - Chuyển trả người gửi";
          statusClassName = "vtp-label-dang-van-chuyen"
        } else if (items.status_viettelpost === "505") {
          statusViettel = "Tồn - Thông báo chuyển hoàn bưu cục gốc";
          statusClassName = "vtp-label-cho-duyet-hoan"
        } else if (items.status_viettelpost === "506") {
          statusViettel = "Tồn - Khách hàng nghỉ, không có nhà";
          statusClassName = "vtp-label-dang-giao-hang"
        } else if (items.status_viettelpost === "507") {
          statusViettel = "Tồn - Khách hàng đến bưu cục nhận";
          statusClassName = "vtp-label-that-bai"
        } else if (items.status_viettelpost === "508") {
          statusViettel = "Phát tiếp";
          statusClassName = "vtp-label-dang-giao-hang";
        } else if (items.status_viettelpost === "509") {
          statusViettel = "Chuyển tiếp bưu cục khác";
          statusClassName = "vtp-label-dang-giao-hang";
        } else if (items.status_viettelpost === "510") {
          statusViettel = "Hủy phân công phát";
          statusClassName = "vtp-label-da-huy"
        } else if (items.status_viettelpost === "511") {
          statusViettel = "Bưu cục phát duyệt hoàn";
          statusClassName = "vtp-label-cho-duyet-hoan"
        } else if (items.status_viettelpost === "550") {
          statusViettel = "Đơn Vị Yêu Cầu Phát Tiếp";
          statusClassName = "vtp-label-dang-giao-hang"
        }
        return {
          key: index + 1,
          id: items.id,
          invoice: items.invoice,
          info_buy: items.info_buy.name,
          phone: items.info_buy.phone,
          infoAddress: items.info_buy,
          status_ghn: items.status_ghn,
          total: items.total.toLocaleString(),
          address: items.info_buy.address,
          created_at: formattedDateTime,
          status: items.status_viettelpost,
          viettel_post_id : items.viettel_post_id,
          status_viettel: <span className={statusClassName}>{statusViettel}</span>,
          items: itemsData,
        };
      });
      searchRef.current = data;
      setFilteredData(data);
    }, [listOrder]);
    const handleDetail = async (orderNumber) => {
      window.open(`https://viettelpost.vn/thong-tin-don-hang?peopleTracking=sender&orderNumber=${orderNumber}`);
    };    
    const handleSearch = () => {
      const results = searchRef.current?.filter((entry) =>
        Object.values(entry).some((value) =>
          value?.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setFilteredData(results);
    };
    const handleReset = () => {
      setSearchText("");
      setFilteredData(searchRef.current);
    };
  
    //cập nhật đơn hàng mới
    const [isLoading, setIsLoading] = useState(false);
    const idw = settings.getCookie("user_idw");
    const loadDataView = () => {
      setIsLoading(true);
      const fetchData = async () => {
        try {
          const [result] = await Promise.all([
            dispatch(LoadDataV2({ idw: idw, page: 'vtp' })),
          ]);
          if (result.payload.status === 200) {
            await store.dispatch(getListOrder({ idw : idw, page:'vtp'}));
            dispatch(setHasFetchedData(true));
            setIsLoading(false);
            message.success("Cập nhật thành công");
          }
        } catch {
          setIsLoading(false);
          message.error("Có lỗi xảy ra");
        }
      }
      fetchData();
    };
    const columns = [
      {
        title: "STT",
        dataIndex: "key",
      },
      {
        title: "Mã hóa đơn ",
        dataIndex: "invoice",
      },
      {
        title: "Thông tin người mua",
        dataIndex: "info_buy",
      },
      {
        title: "Số điện thoại ",
        dataIndex: "phone",
      },
      {
        title: "Tổng giá tiền ",
        dataIndex: "total",
      },
      {
        title: "Địa chỉ chi tiết",
        dataIndex: "address",
      },
      {
        title: "Thời gian đặt đơn",
        dataIndex: "created_at",
      },
      {
        title: "Trạng thái",
        dataIndex: "status_viettel",
      },
      {
        title: "Thao tác",
        dataIndex: "operation",
        render: (_, record) => (
          <Space>
            <CreateOrder id={record.id} status={record.status} />
            <Tooltip title="Chi tiết đơn hàng">
             {record.status !== "new" ? (
                <span
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                    opacity: 1,
                  }}
                  onClick={() => handleDetail(record.viettel_post_id)}
                >
                  <EyeTwoTone />
                </span>
              ) : (
                <span
                  style={{
                    fontSize: 20,
                    cursor: "not-allowed",
                    opacity: 0.5,
                  }}
                >
                  <EyeInvisibleTwoTone />
                </span>
              )}
            </Tooltip>
          </Space>
        ),
      },
    ];
    return (
      <div style={{ marginTop: 16 }}>
        <span style={{ color: "#7790b6", fontSize: "16px" }}>Viettel Post</span>
        <RightOutlined style={{ margin: "0 20px", fontSize: 10 }} />
        <span style={{ color: "#7790b6", fontSize: "16px" }}>
          Danh sách đơn hàng
        </span>
        <Divider />
        <Row
          align={"middle"}
          style={{ marginBottom: 16 }}
        >
          <Col span={20}>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                  <Input
                    placeholder="Tìm kiếm theo mã hoá hoá đơn"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={handleSearch}
                  />
                </Col>
                <Col xl lg md sm>
                  <Button
                    type="primary"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  >
                    Tìm kiếm đơn hàng
                  </Button>
                </Col>
                <Col xl lg md sm>
                  <Button onClick={handleReset}>Đặt lại</Button>
                </Col>
            </Row>
          </Col>
          <Col span={4} style={{textAlign:"right"}}>
            <Button
              type="primary"
              icon={<SyncOutlined /> }
              onClick={loadDataView}
            >
              Cập nhật đơn hàng
            </Button>
          </Col>
        </Row>
        <Table
          bordered={true}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => {
              const columns = [
                {
                  title: "Tên sản phẩm",
                  dataIndex: "name",
                },
                {
                  title: "Số lượng ",
                  dataIndex: "quantity",
                  align: "center",
                },
                {
                  title: "Giá sản phẩm",
                  dataIndex: "price",
                  align: "center",
                },
                {
                  title: "Hình ảnh",
                  dataIndex: "image",
                  align: "center",
                },
              ];
              const data = record.items.map((item, index) => {
                const imageUrl = isSSL
                  ? item.image.replace("http://upload2.webbnc.vn", "https://cdn-img-v2.webbnc.net")
                  : item.image;
                return {
                  key: index,
                  name: item.name,
                  quantity: item.quantity,
                  price: item.price.toLocaleString(),
                  image: <Image key={index} width={40} src={imageUrl} />,
                };
              });
  
              return (
                <Table
                  columns={columns}
                  dataSource={data}
                  size="small"
                  pagination={false}
                  bordered
                />
              );
            },
          }}
          loading={isLoadOrder || isLoading}
          dataSource={filteredData}
          pagination={{
            showQuickJumper: true,
            showTotal: (total, range) =>
              `Hiển thị kết quả từ ${range[0]} đến ${range[1]} trên tổng ${total}`,
          }}
        />
      </div>
    );
  }
  