import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { http } from '../../utils/config';

export const ServiceViettelPostXtra = createAsyncThunk(
    'ViettelPost/ServiceViettelPostXtra',
    async (serviceCode) => {
      const response = await http.post(`/get-service-extra-viettelpost`, {
        service_code: serviceCode,
      });
      return response.data.data;
    }
  );

const serviceViettelPostXtra = createSlice({
  name: 'ServiceViettelPostXtra',
  initialState: {
    isFetching: false,
    servicelistxtra: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ServiceViettelPostXtra.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(ServiceViettelPostXtra.fulfilled, (state, action) => {
        state.servicelistxtra = action.payload.data || [];
        state.isFetching = false;
      })
      .addCase(ServiceViettelPostXtra.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.error.message;
      });
  },
});

export default serviceViettelPostXtra.reducer;
