import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    data: null,
    isLoading: false
}
export const serviceAhamove = createAsyncThunk("Ahamove/ServiceAhamove", async (data) => {
    const response = await http.post("/many-services-fee", data);
    return response.data;
});

const ServiceAhamove = createSlice({
    name: "ServiceAhamove",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(serviceAhamove.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(serviceAhamove.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(serviceAhamove.rejected, (state, action) => {
                state.isLoading = false;
                // state.err = action.payload.message;

            })
    },
});

export default ServiceAhamove.reducer