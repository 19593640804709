import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESSTOKEN_LAZADA, httplazada, settings } from "../../utils/config";

const initialState = {
  createUserLazada: null,
  userShoplazada: [],
  userNameShop: "",
  isLoadCreateLazada: false,
  isLoaduser: false,
};

export const getNameUserLazada = createAsyncThunk(
  "lazada/getNameUserLazada",
  async (token) => {
    const response = await httplazada.get(
      `/get-seller-lazada?access_token=${token}`
    );
    return response.data.data.data;
  }
);

export const getCreateUserLazada = createAsyncThunk(
  "Lazada/getCreateUserLazada",
  async (data) => {
    try {
      const response = await httplazada.post(`/stall-lazada`, data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

const UrlgetSellerLazada = createSlice({
  name: "UrlgetSellerLazada",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNameUserLazada.pending, (state) => {
        state.isLoadCreateLazada = true;
      })
      .addCase(getNameUserLazada.fulfilled, (state, action) => {
        state.userShoplazada = action.payload;
        state.userNameShop = action.payload.name;
        state.isLoadCreateLazada = false;
      })
      .addCase(getNameUserLazada.rejected, (state, action) => {
        state.isLoadCreateLazada = false;
      })
      .addCase(getCreateUserLazada.pending, (state) => {
        state.isLoaduser = true;
      })
      .addCase(getCreateUserLazada.fulfilled, (state, action) => {
        state.isLoaduser = false;
      })
      .addCase(getCreateUserLazada.rejected, (state, action) => {
        state.isLoaduser = false;
      });
  },
});

export default UrlgetSellerLazada.reducer;
