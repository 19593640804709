import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    isloadSerPack: false,
    packService: null
}
export const getServicePack = createAsyncThunk(
    'Giaohangnhanh/getServicePack',
    async (data) => {
        const response = await http.post(
            `/service-pack`, data
        );
        return response.data.data;
    }
);
const getTheServicePack = createSlice({
    name: "getTheServicePack",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //Api gọi lấy gói dịch vụ
            .addCase(getServicePack.pending, (state) => {
                state.isloadSerPack = true;
            })
            .addCase(getServicePack.fulfilled, (state, action) => {
                state.packService = action.payload?.map((service) => ({
                    value: service.service_id,
                    label: service.short_name,
                }));
                state.isloadSerPack = false;
            })
            .addCase(getServicePack.rejected, (state, action) => {
                state.isloadSerPack = false;
                state.error = action.error.message;
            })

    },

});

export const { } = getTheServicePack.actions

export default getTheServicePack.reducer