import {
  Button,
  Col,
  Divider,
  Image,
  Input,
  Row,
  Space,
  Table,
  Tooltip,
  message,
  Modal
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import formatDateTime from "../../../utils/dateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  EyeInvisibleTwoTone,
  EyeTwoTone,
  RightOutlined,
  SearchOutlined,
  SyncOutlined
} from "@ant-design/icons";
import { store } from "../../../redux/configStore";
import { getListOrder } from "../../../redux/Giaohangnhanh/listProductOrder";
import { settings } from "../../../utils/config";
import CreateOrder from "./ModalGrap/CreateOrder";
import { useNavigate } from "react-router-dom";
import { LoadDataV2 } from "../../../redux/Login/Login";
import { setHasFetchedData } from "../../../redux/GrapExpress/CreateOrderGrap";
import "../../../../src/assets/scss/pages/Grabcss/Coupon.css";
export default function HomeGrap() {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { listOrder, isLoadOrder } = useSelector(
    (state) => state.persistedReducer.listProductOrder
  );
  const dispatch = useDispatch();
  const { hasFetchedData } = useSelector(
    (state) => state.persistedReducer.CreateOrderGrap
  );
  const isSSL = window.location.protocol === "https:";
  const navigate = useNavigate();
  const searchRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await store.dispatch(getListOrder({ idw : settings.getCookie("user_idw"), page:'grab'}));
        dispatch(setHasFetchedData(true));
      } catch (error) {
        message.error("Có lỗi xảy ra không tồn tại dữ liệu ");
      }
    };

    if (!hasFetchedData) {
      fetchData();
    }
  }, [hasFetchedData, dispatch]);
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    const data = listOrder?.map((items, index) => {
      const itemsData = items.productofcart.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: parseFloat(item.price),
        image: item.image,
      }));
      const formattedDateTime = formatDateTime(items.created_at);
      let statusGrab = "";
      let statusStyle = {};
      if (items.status_grab === "new") {
        statusGrab = "Đơn hàng mới";
        statusStyle = { color: "#bfbfbf", fontWeight: 500 };
      } else if (items.status_grab === "ALLOCATING") {
        statusGrab = "Đang tìm tài xế";
        statusStyle = { color: "#1677ff", fontWeight: 500 };
      } else if (items.status_grab === "PENDING_PICKUP") {
        statusGrab = "Chờ lấy hàng";
        statusStyle = { color: "#fadb14", fontWeight: 500 };
      } else if (items.status_grab === "PICKING_UP") {
        statusGrab = "Đang lấy hàng";
        statusStyle = { color: "#fadb14", fontWeight: 500 };
      } else if (items.status_grab === "PENDING_DROP_OFF") {
        statusGrab = "Chờ giao hàng";
        statusStyle = { color: "#722ed1", fontWeight: 500 };
      } else if (items.status_grab === "IN_DELIVERY") {
        statusGrab = "Đang giao hàng";
        statusStyle = { color: "#fadb14", fontWeight: 500 };
      } else if (items.status_grab === "CANCELED") {
        statusGrab = "Đã hủy";
        statusStyle = { color: "#f5222d", fontWeight: 500 };
      } else if (items.status_grab === "FAILED") {
        statusGrab = "Giao hàng thất bại";
        statusStyle = { color: "#f5222d", fontWeight: 500 };
      } else if (items.status_grab === "COMPLETED") {
        statusGrab = "Đã giao hàng";
        statusStyle = { color: "#52c41a", fontWeight: 500 };
      }
      return {
        key: index + 1,
        id: items.id,
        invoice: items.invoice,
        info_buy: items.info_buy.name,
        phone: items.info_buy.phone,
        infoAddress: items.info_buy,
        status_ghn: items.status_ghn,
        total: items.total.toLocaleString(),
        address: items.info_buy.address,
        created_at: formattedDateTime,
        status: items.status_grab,
        status_grab: <span style={statusStyle}>{statusGrab}</span>,
        items: itemsData,
      };
    });
    searchRef.current = data;
    setFilteredData(data);
  }, [listOrder]);
  const handleDetail = async (sku) => {
    navigate(`/detailOrder/${sku}`);
  };
  const handleSearch = () => {
    const results = searchRef.current?.filter((entry) =>
      Object.values(entry).some((value) =>
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredData(results);
  };
  const handleReset = () => {
    setSearchText("");
    setFilteredData(searchRef.current);
  };
  const handleCopy = (coupon) => {
    navigator.clipboard.writeText(coupon);
    setIsCopied(true);
  
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  const handleConditionClick = () => {
    const couponHtml = `
      <div class="coupon-row">
        <div class="coupon-label">Mã khuyến mãi:</div><span class="code">SIEUTOC</span>
      </div>
      <div class="coupon-row">
        <div class="coupon-label">Điều kiện:</div><div class="coupon-info">
        - Thời gian ưu đãi: Từ 27/12/2023 – 31/01/2024 <br />
        - Đối tượng áp dụng: Khách hàng mới và đang sử dụng dịch vụ của GrabExpress trên Bota <br />
        - Dịch vụ áp dụng: Giao hàng Siêu tốc, Siêu tốc Cỡ lớn <br />
        - Khu vực áp dụng: TP. Hồ Chí Minh, TP. Hà Nội, TP. Đà Nẵng
        </div>
      </div>
    `;

    setModalContent(couponHtml);
    setModalVisible(true);
  };

  //cập nhật đơn hàng mới
  const [isLoading, setIsLoading] = useState(false);
  const idw = settings.getCookie("user_idw");
  const loadDataView = () => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const [result] = await Promise.all([
          dispatch(LoadDataV2({ idw: idw, page: 'grab' })),
        ]);
        if (result.payload.status === 200) {
          await store.dispatch(getListOrder({ idw : idw, page:'grab'}));
          dispatch(setHasFetchedData(true));
          setIsLoading(false);
          message.success("Cập nhật thành công");
        }
      } catch {
        setIsLoading(false);
        message.error("Có lỗi xảy ra");
      }
    }
    fetchData();
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
    },
    {
      title: "Mã hóa đơn ",
      dataIndex: "invoice",
    },
    {
      title: "Thông tin người mua",
      dataIndex: "info_buy",
    },
    {
      title: "Số điện thoại ",
      dataIndex: "phone",
    },
    {
      title: "Tổng giá tiền ",
      dataIndex: "total",
    },
    {
      title: "Địa chỉ chi tiết",
      dataIndex: "address",
    },
    {
      title: "Thời gian đặt đơn",
      dataIndex: "created_at",
    },
    {
      title: "Trạng thái đơn hàng",
      dataIndex: "status_grab",
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      render: (_, record) => (
        <Space>
          <CreateOrder id={record.id} status={record.status} />
          <Tooltip title="Chi tiết đơn hàng">
            {record.status !== "new" ? (
              <span
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  opacity: 1,
                }}
                onClick={() => handleDetail(record.invoice)}
              >
                <EyeTwoTone />
              </span>
            ) : (
              <span
                style={{
                  fontSize: 20,
                  cursor: "not-allowed",
                  opacity: 0.5,
                }}
              >
                <EyeInvisibleTwoTone />
              </span>
            )}
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ marginTop: 16 }}>
      <span style={{ color: "#7790b6", fontSize: "16px" }}>GrabExpress</span>
      <RightOutlined style={{ margin: "0 20px", fontSize: 10 }} />
      <span style={{ color: "#7790b6", fontSize: "16px" }}>
        Danh sách đơn hàng
      </span>
      <Divider />
      <Row
        align={"middle"}
        style={{ marginBottom: 16 }}
      >
         <Col span={30}>
            <div className="coupon_item">
                <div className="coupon_icon">
                  <img
                    className="img-fluid"
                    src="https://cdn-img-v2.webbnc.net/media/10201/coupon.png"
                    alt="coupon"
                    width="79"
                    height="70"
                  />
                </div>
                <div className="coupon_body">
                  <div className="coupon_head">
                    <h3 className="coupon_title">NHẬP MÃ: SIEUTOC</h3>
                    <div className="coupon_desc">
                      Giảm 30% tối đa 15K/đơn với gói GrabExpress trên Bota
                    </div>
                  </div>
                  <div className="coupon_body_between">
                  <button
                      className={`coupon_copy ${isCopied ? 'disabled' : ''}`}
                      data-ega-coupon="SIEUTOC"
                      onClick={() => handleCopy("SIEUTOC")}
                    >
                      <span>{isCopied ? "Đã chép" : "Sao chép"}</span>
                    </button>
                    <span
                      className="coupon_info_toggle"
                      data-coupon="SIEUTOC"
                      onClick={() => handleConditionClick("SIEUTOC", "Thông tin điều kiện", "")}
                    >
                      Điều kiện
                    </span>
                  </div>
                </div>
            </div>
          </Col>
          <Modal
              title="NHẬP MÃ: SIEUTOC"
              visible={modalVisible}
              className="bota_coupon_modal"
              onCancel={() => setModalVisible(false)}
              footer={[
                <Button key="back" onClick={() => setModalVisible(false)} className="bota_coupon_modal_close">
                  Đóng
                </Button>,
                <Button key="copy" className={`bota_coupon_modal_copy ${isCopied ? 'disabled' : ''}`}
                data-ega-coupon="SIEUTOC"
                onClick={() => handleCopy("SIEUTOC")}>
                  {isCopied ? "Đã chép" : "Sao chép"}
                </Button>
              ]}
            >
              <div dangerouslySetInnerHTML={{ __html: modalContent }} />
            </Modal>
      </Row>
      <Row
        align={"middle"}
        style={{ marginBottom: 16 }}
      >
        <Col span={20}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
                <Input
                  placeholder="Tìm kiếm theo mã hoá hoá đơn"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onPressEnter={handleSearch}
                />
              </Col>
              <Col xl lg md sm>
                <Button
                  type="primary"
                  onClick={handleSearch}
                  icon={<SearchOutlined />}
                >
                  Tìm kiếm đơn hàng
                </Button>
              </Col>
              <Col xl lg md sm>
                <Button onClick={handleReset}>Đặt lại</Button>
              </Col>
          </Row>
        </Col>
        <Col span={4} style={{textAlign:"right"}}>
          <Button
            type="primary"
            icon={<SyncOutlined /> }
            onClick={loadDataView}
          >
            Cập nhật đơn hàng
          </Button>
        </Col>
      </Row>
      <Table
        bordered={true}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            const columns = [
              {
                title: "Tên sản phẩm",
                dataIndex: "name",
              },
              {
                title: "Số lượng ",
                dataIndex: "quantity",
                align: "center",
              },
              {
                title: "Giá sản phẩm",
                dataIndex: "price",
                align: "center",
              },
              {
                title: "Hình ảnh",
                dataIndex: "image",
                align: "center",
              },
            ];
            const data = record.items.map((item, index) => {
              const imageUrl = isSSL
                ? item.image.replace("http://upload2.webbnc.vn", "https://cdn-img-v2.webbnc.net")
                : item.image;
              return {
                key: index,
                name: item.name,
                quantity: item.quantity,
                price: item.price.toLocaleString(),
                image: <Image key={index} width={40} src={imageUrl} />,
              };
            });

            return (
              <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
                bordered
              />
            );
          },
        }}
        loading={isLoadOrder || isLoading}
        dataSource={filteredData}
        pagination={{
          showQuickJumper: true,
          showTotal: (total, range) =>
            `Hiển thị kết quả từ ${range[0]} đến ${range[1]} trên tổng ${total}`,
        }}
      />
    </div>
  );
}
