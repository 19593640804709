import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { settings } from "../utils/config";
export default function PrivativeCheckLogin() {
  return settings?.getCookie("user_token") ? (
    <Navigate to='/' />
  ) : (
    <Outlet></Outlet>
  );
}
