import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {http} from '../../utils/config';

const initialState = {
    createAhamove: null,
    isLoadingOrder: false,
    hasFetchedData: false,
}
export const createOrderAhamove = createAsyncThunk("Ahamove/CreateOrderAhamove", async (data) => {
    try {
        const response = await http.post("/create-order-ahamove", data);
        return response.data;
    } catch (error) {
        throw new Error("Có lỗi xảy ra");
    }
});
const CreateOrderAhamove = createSlice({
    name: "CreateOrderAhamove",
    initialState,
    reducers: {
        setHasFetchedData: (state, action) => {
            state.hasFetchedData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrderAhamove.pending, (state) => {
                state.isLoadingOrder = true;
            })
            .addCase(createOrderAhamove.fulfilled, (state, action) => {
                state.isLoadingOrder = false;
                state.createAhamove = action.payload;
            })
            .addCase(createOrderAhamove.rejected, (state, action) => {
                state.isLoadingOrder = false;
                // state.err = action.payload.message;

            })
    },
});

export const {setHasFetchedData} = CreateOrderAhamove.actions

export default CreateOrderAhamove.reducer
