import { Button, Result } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

export default function Page404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Xin lỗi,trang không tồn tại"
      extra={
        <NavLink to={"/"}>
          <Button type="primary">Quay về trang chủ</Button>
        </NavLink>
      }
    />
  );
}
