import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESSTOKEN_LAZADA, httplazada, settings } from "../../utils/config";

const initialState = {
  dataSynclazada: [],
  isLoadSyncLazada: false,
  isLoadSyncLazadaPrice: false,
  isLoadSyncLazadaStock: false,
};
export const PostSyncLazada = createAsyncThunk(
  "lazada/PostSyncLazada",
  async (data) => {
    const response = await httplazada.post("/create-product-lazada", data);
    return response.data;
  }
);
export const UpdateSyncLazada = createAsyncThunk(
  "lazada/UpdateSyncLazada",
  async (data) => {
    const response = await httplazada.post("/updateStatus-lazada", data);
    return response.data;
  }
);

export const UpdateSyncPriceLazada = createAsyncThunk(
  "lazada/UpdateSyncPriceLazada",
  async (data) => {
    const response = await httplazada.post("/update-price-lazada", data);
    return response.data.data;
  }
);

export const UpdateSyncStockLazada = createAsyncThunk(
  "lazada/UpdateSyncStockLazada",
  async (data) => {
    const response = await httplazada.post("/update-stock-lazada", data);
    return response.data.data;
  }
);

const UrlSyncLazada = createSlice({
  name: "UrlSyncLazada",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // thêm sản phẩm
      .addCase(PostSyncLazada.pending, (state) => {
        state.isLoadSyncLazada = true;
      })
      .addCase(PostSyncLazada.fulfilled, (state, action) => {
        state.dataSynclazada = action.payload;
        state.isLoadSyncLazada = false;
      })
      .addCase(PostSyncLazada.rejected, (state, action) => {
        state.isLoadSyncLazada = false;
      })
      // cập nhật trạng thái
      .addCase(UpdateSyncLazada.pending, (state) => {
        state.isLoadSyncLazada = true;
      })
      .addCase(UpdateSyncLazada.fulfilled, (state, action) => {
        state.isLoadSyncLazada = false;
      })
      .addCase(UpdateSyncLazada.rejected, (state, action) => {
        state.isLoadSyncLazada = false;
      })
      // update price
      .addCase(UpdateSyncPriceLazada.pending, (state) => {
        state.isLoadSyncLazadaPrice = true;
      })
      .addCase(UpdateSyncPriceLazada.fulfilled, (state, action) => {
        state.isLoadSyncLazadaPrice = false;
      })
      .addCase(UpdateSyncPriceLazada.rejected, (state, action) => {
        state.isLoadSyncLazadaPrice = false;
      })
      // update quantity
      .addCase(UpdateSyncStockLazada.pending, (state) => {
        state.isLoadSyncLazadaStock = true;
      })
      .addCase(UpdateSyncStockLazada.fulfilled, (state, action) => {
        state.isLoadSyncLazadaStock = false;
      })
      .addCase(UpdateSyncStockLazada.rejected, (state, action) => {
        state.isLoadSyncLazadaStock = false;
      });
  },
});

export default UrlSyncLazada.reducer;
