import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { http } from '../../utils/config';
import { message } from "antd";

export const CreateOrderAllViettelPost = createAsyncThunk(
    'ViettelPost/CreateOrderAllViettelPost',
    async (data) => {
      const response = await http.post(`/viettelpost-createOrder`, data,);
      if(response.data.status === 200){
        const params = {};
          params.id = data.cart_id;
          params.ORDER_NUMBER = response.data.data.data.ORDER_NUMBER;

        const responseAdd = await http.post(`/add-viettelpost-id`, params);
        // console.log('responseAdd:', responseAdd);
        return response;
      }
      // return response.data.data;
    }
  );

const createOrderAllViettelPost = createSlice({
  name: 'createOrderAllViettelPost',
  initialState: {
    isFetching: false,
    servicelistxtra: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateOrderAllViettelPost.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(CreateOrderAllViettelPost.fulfilled, (state, action) => {
        state.servicelistxtra = action.payload.data || [];
        state.isFetching = false;
      })
      .addCase(CreateOrderAllViettelPost.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.error.message;
      });
  },
});

export default createOrderAllViettelPost.reducer;
