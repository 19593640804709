import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http, https } from "../../utils/config";
import { settings } from "../../utils/config";

const initialState = {
  emailUser: null,
  domain_web: null,
  userLogin: null,
  userData: null,
  isLoadLogin: false,
};

export const checkLogin = createAsyncThunk("Login/checkLogin", async (data) => {
  const response = await https.post("/check-login", data);
  // console.log(response.data.data);
  return response.data.data.original;
});
export const refreshTokenLogin = createAsyncThunk("Login/refreshTokenLogin", async () => {
  const response = await https.post("/refresh-token");
  return response.data.data.original;
});

export const getUserData = createAsyncThunk("Login/getUserData", async () => {
  const response = await https.get("/detail-user");
  return response.data.data;
});
export const LoadDataProduct = createAsyncThunk("Login/LoadDataProduct", async (idw) => {
  const response = await https.post("/list-product",idw);
  return response.data;
});
export const LoadDataCart = createAsyncThunk("Login/LoadDataCart", async (data) => {
  const response = await https.post("/list-cart-v2",data);
  return response.data;
});

export const LoadDataV2 = createAsyncThunk("Login/LoadDataCart", async (data) => {
  // console.log("LoadDataV2",data);
  const response = await https.post("/list-cart-v2",data);
  return response.data;
});
const Login = createSlice({
  name: "Login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkLogin.pending, (state) => {
        state.isLoadLogin = true;
      })
      .addCase(checkLogin.fulfilled, (state, action) => {
        state.userLogin = action.payload;
        settings.setCookie("user_token", action?.payload?.access_token, 1);
        settings.setCookie("refresh_tokenUser", action?.payload?.refresh_token, 10);
        settings.setCookie("user_idw", action?.payload?.web_id, 1);
        state.isLoadLogin = false;
      })
      .addCase(checkLogin.rejected, (state, action) => {
        state.isLoadLogin = false;
        state.error = action.error.message;
      })
      .addCase(getUserData.pending, (state) => {})
      .addCase(getUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

// export const {} = Login.actions;

export default Login.reducer;
