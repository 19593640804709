import React, { useEffect } from "react";
import { settings } from "../utils/config";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { refreshToken } from "../redux/Shopee/getUrl";
import { store } from "../redux/configStore";


export default function PrivateRouterShopee() {
  const navigate=useNavigate()
  const accessToken = settings.getCookie("access_token");
  const refresh_token = settings.getCookie("refresh_token");
  const shopId = settings.getCookie("shopId");
  useEffect(() => {
    async function fetchNewToken() {
      try {
        // Gửi yêu cầu lấy token mới
        const response = await store.dispatch(
          refreshToken({
            refresh_token: refresh_token,
            shop_id: parseFloat(shopId),
          })
        );
        // console.log(response);
        // Cập nhật access token mới
        const newAccessToken = response.payload.access_token;
        // const newExpireIn = response.payload.expire_in;
        // Cập nhật trong cookie
        settings.setCookie("access_token", newAccessToken, 30);
        // settings.setCookie("expireIn", newExpireIn, 30);
      } catch (error) {
       navigate("/ecommerce")
      }
    }
    if (!accessToken) {
      fetchNewToken();
    }
    // Thiết lập interval để gọi lại fetchNewToken() sau mỗi 3 giờ
    const interval = setInterval(fetchNewToken, 3 * 60 * 60 * 1000);
    // Xóa interval khi component unmount
    return () => clearInterval(interval);
  }, [accessToken, refresh_token, shopId,navigate]);

  return settings?.getCookie("access_token") ? <Outlet /> : <Navigate to="/" />;
}
