import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http, settings } from '../../utils/config';


const initialState = {
    url: "",
    isLoadUrl: false,
    data: null,
    isLoadToken: false,
    refresh: null,
    loadRefresh: false
}
export const getUrlRedirect = createAsyncThunk(
    'Shopee/getUrlRedirect',
    async () => {
        const response = await http.get(
            `/login-shopee`
        );
        return response.data.data;
    }
);
export const getToken = createAsyncThunk(
    'Shopee/getToken',
    async (data) => {
        const response = await http.post(
            `/get-token-shopee`, data
        );
        // console.log(response)
        return response.data.data;
    }
);
export const refreshToken = createAsyncThunk(
    'Shopee/refreshToken',
    async (data) => {
        const response = await http.post(
            `/get-refresh-token-shopee`, data
        );
        // console.log(response.data.data)
        return response.data.data;
    }
);
const getUrl = createSlice({
    name: "getUrl",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUrlRedirect.pending, (state) => {
                state.isLoadUrl = true;
            })
            .addCase(getUrlRedirect.fulfilled, (state, action) => {
                state.url = action.payload
                state.isLoadUrl = false;
            })
            .addCase(getUrlRedirect.rejected, (state, action) => {
                state.isLoadUrl = false;
                // state.eroi = action.error.message;
            })

            .addCase(getToken.pending, (state) => {
                state.isLoadToken = true;
            })
            .addCase(getToken.fulfilled,  (state, action) => {
                state.data = action.payload
                state.isLoadToken = false;
                settings.setCookie("access_token", action.payload.access_token, 1 / 6)
                settings.setCookie("refresh_token", action.payload.refresh_token, 30)
            })
            .addCase(getToken.rejected, (state, action) => {
                state.isLoadToken = false;
            })
            .addCase(refreshToken.pending, (state) => {
                state.loadRefresh = true;
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.refresh = action.payload
                state.loadRefresh = false;
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.loadRefresh = false;
            })
    },
});

// export const { } = getUrl.actions

export default getUrl.reducer