import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {http} from '../../utils/config';

const initialState = {
    createGrab: null,
    isLoadingOrder: false,
    hasFetchedData: false,
}
export const orderGrap = createAsyncThunk("GrapExpress/orderGrap", async (data) => {
    try {
        const response = await http.post("/order-grab", data);
        return response.data.data;
    } catch (error) {
        throw new Error("Có lỗi xảy ra");
    }
});
export const deleteOrderGrab = createAsyncThunk("GrapExpress/deleteOrderGrab", async (id) => {
    try {
        const response = await http.get(`/cancel-delivery?deliveryID=${id}`)
        return response;
    } catch (error) {
        throw new Error("Có lỗi xảy ra");
    }
});
export const updateStatusGrab = createAsyncThunk("GrapExpress/updateStatusGrab", async (data) => {
    try {
        const response = await http.post("/update-status-grab", data);
        return response.data.data;
    } catch (error) {
        throw new Error("Có lỗi xảy ra");
    }
});
const CreateOrderGrap = createSlice({
    name: "CreateOrderGrap",
    initialState,
    reducers: {
        setHasFetchedData: (state, action) => {
            state.hasFetchedData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(orderGrap.pending, (state) => {
                state.isLoadingOrder = true;
            })
            .addCase(orderGrap.fulfilled, (state, action) => {
                state.isLoadingOrder = false;
                state.createGrab = action.payload;
            })
            .addCase(orderGrap.rejected, (state, action) => {
                state.isLoadingOrder = false;
                // state.err = action.payload.message;

            })
    },
});

export const {setHasFetchedData} = CreateOrderGrap.actions

export default CreateOrderGrap.reducer
