import {
  PlusCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  DashboardOutlined,
  ShoppingCartOutlined
} from "@ant-design/icons";
import { Dvvc, SendoSvg, LazadaSvg, ShopeeSvg, SvgTiktok } from "../SvgIcon";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const items = [
  getItem("Tổng quan", "/", <DashboardOutlined />),
  getItem("Đơn Hàng", "/order", <ShoppingCartOutlined />),
  getItem("Kênh bán hàng", "tmdt", <ShopOutlined />, [
    getItem("Sendo", "/sendo", SendoSvg, [
      getItem("Đăng sản phẩm", "/create-product", <PlusCircleOutlined />),
      getItem("Cấu hình", "settings", <SettingOutlined />),
    ]),
    getItem("Shopee", "/shopee", ShopeeSvg, [
      getItem("Đăng sản phẩm", "/new-sync-shopee", <PlusCircleOutlined />),
      getItem("Cấu hình", "logout", <SettingOutlined />),
    ]),
    getItem("TikTok", "/tiktok", SvgTiktok, [
      getItem("Đăng sản phẩm", "/sync-Tiktok", <PlusCircleOutlined />),
      getItem("Cấu hình", "logoutTiktok", <SettingOutlined />),
    ]),
    getItem("Lazada", "/lazada", LazadaSvg, [
      getItem("Đăng sản phẩm", "/add-product-lazada", <PlusCircleOutlined />),
      getItem("Cấu hình", "/logout-lazada", <SettingOutlined />),
    ]),
  ]),
  getItem("Vận chuyển", "/dvvc", Dvvc, [
     getItem("Giao Hàng Nhanh", "/ghn", null, [
      getItem("Tạo đơn hàng", "/create-order", <PlusCircleOutlined />),
      getItem("Cấu hình", "/logoutGhn", <SettingOutlined />),
    ]),
    getItem("Grap Express", "/grap", null, [
      getItem("Tạo đơn hàng", "/create-order-grap", <PlusCircleOutlined />),
      getItem("Cấu hình", "/logoutGrap", <SettingOutlined />),
    ]),
  (window.location.hostname === 'localhost') && getItem("VietNam Post", "/vnp", null, [
      getItem("Tạo đơn hàng", "/create-order-vnp", <PlusCircleOutlined />),
      getItem("Cấu hình", "/logoutVnp", <SettingOutlined />),
    ]),
  getItem("Viettel Post", "/viettelpost", null, [
      getItem("Tạo đơn hàng", "/create-order-ViettelPost", <PlusCircleOutlined />),
      getItem("Cấu hình", "/logoutViettelPost", <SettingOutlined />),
    ]),
   getItem("Ahamove", "/ahamove", null, [
      getItem("Tạo đơn hàng", "/create-order-Ahamove", <PlusCircleOutlined />),
      getItem("Cấu hình", "/logoutAhamove", <SettingOutlined />),
    ]),
  ]),
  getItem("Cấu hình kết nối", "", <SettingOutlined />, [
    getItem("Kênh bán hàng", "/ecommerce", <SettingOutlined />),
    getItem("Vận chuyển", "/vanchuyen", <SettingOutlined />),
  ]),
];
