import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const loadingLazada = (
  <LoadingOutlined
    style={{
      fontSize: 50,
    }}
    spin
  />
);

export default function Loading() {
  return (
    <div className='loadingLazada'>
      <Spin indicator={loadingLazada} />
    </div>
  );
}
