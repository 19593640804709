import React, { useEffect, useState } from "react";
import logo_bota from "../../assets/img/logo_bota.png";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { persistor, store } from "../../redux/configStore";
import { settings } from "../../utils/config";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { DownOutlined, LogoutOutlined, UpOutlined } from "@ant-design/icons";
import { getUserData } from "../../redux/Login/Login";
export const ACCESSTOKEN = "token";
export const ACCESSTOKEN_LAZADA = "token_lazada";

export default function HeaderBota() {
  const { userData } = useSelector((state) => state.persistedReducer.Login);
  const [open, setOpen] = useState(false);
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };
  const user_token = settings.getCookie("user_token");
  useEffect(() => {
    const userData = async () => {
      await store.dispatch(getUserData());
    };
    userData();
  }, []);
  const logOut = () => {
    persistor.pause();
    persistor.flush().then(() => {
      settings.delete_cookie("user_token");
      settings.delete_cookie("refresh_tokenUser");
      settings.delete_cookie("user_idw");
      settings.delete_cookie("vietel_post_token");
      settings.delete_cookie("accessTokenAhamove");
      settings.delete_cookie("tokenGhn");
      settings.delete_cookie(ACCESSTOKEN);
      settings.delete_cookie("accessTokenGrap");
      settings.delete_cookie("access_tokenTikTok");
      settings.delete_cookie(ACCESSTOKEN_LAZADA);
      window.location.href = "http://admin.bncvn.vn/";
      return persistor.purge();
    });
  };
  const items = [
    {
      label: (
        <span style={{ color: "#0089ff" }}>
          <b>{userData?.domain_web}</b>
        </span>
      ),
      key: "0",
    },
    {
      label: (
        <span>
          <NavLink>{userData?.email}</NavLink>
        </span>
      ),
      key: "1",
    },
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <NavLink to={"/"} style={{ display: "flex" }}>
          <img style={{ width: "117px" }} src={logo_bota} alt="logo" />
        </NavLink>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 44,
          alignItems: "center",
        }}
      >
        <div
          className="header_right"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space size={16}>
            {user_token ? (
              <>
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                  onOpenChange={handleOpenChange}
                  open={open}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      color: "black",
                    }}
                  >
                    <div style={{ lineHeight: "20px", marginRight: "10px" }}>
                      <NavLink
                        style={{
                          width: "100%",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: "16px",
                          fontWeight: 700,
                          padding: "5px 0",
                        }}
                      >
                        {userData?.name ? userData?.name : userData?.email}
                      </NavLink>
                    </div>
                    {open ? <UpOutlined /> : <DownOutlined />}
                  </div>
                </Dropdown>
                <Tooltip title="Đăng xuất">
                  <Button
                    style={{ borderRadius: 50,boxShadow:"rgba(9, 30, 66, 0.13) 0px 0px 1px 1px" }}
                    type="primary"
                    icon={<LogoutOutlined />}
                    danger
                    onClick={logOut}
                  ></Button>
                </Tooltip>
              </>
            ) : (
              <NavLink to={"/login"}>Đăng Nhập</NavLink>
            )}
          </Space>
        </div>
      </div>
    </>
  );
}
