import { Button, Form, Input, InputNumber, Select, Space, message } from "antd";
import React, { useState } from "react";
import Locate from "../Map/Locate";
import Search from "../Map/Search";
import { store } from "../../../../redux/configStore";
import { freeShip } from "../../../../redux/GrapExpress/FreeShipGrap";
import { useSelector } from "react-redux";
import formatDateTime from "../../../../utils/dateTime";
import {
  DollarOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  orderGrap,
  updateStatusGrab,
} from "../../../../redux/GrapExpress/CreateOrderGrap";
import { getListOrder } from "../../../../redux/Giaohangnhanh/listProductOrder";
import { settings } from "../../../../utils/config";

export default function FormCreate({ id }) {
  const { isLoading, data } = useSelector(
    (state) => state.persistedReducer.FreeShipGrap
  );
  const { isLoadingOrder, createGrab } = useSelector(
    (state) => state.persistedReducer.CreateOrderGrap
  );
  const [isPromoCodeEntered, setIsPromoCodeEntered] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [form] = Form.useForm();
  const [checkId, setCheckId] = useState();
  const [locateData, setLocateData] = useState({});
  const [searchData, setSearchData] = useState({});
  const [showShippingFee, setShowShippingFee] = useState(false);
  const pickupTime = data?.quotes[0]?.estimatedTimeline?.pickup;
  const dropoffTime = data?.quotes[0]?.estimatedTimeline?.dropoff;
  const pickup = formatDateTime(pickupTime);
  const dropoff = formatDateTime(dropoffTime);
  const handleLocateChange = (locate) => {
    setLocateData(locate);
    form.setFieldsValue({ sender: locate?.formatted_address });
  };
  const handleSearchChange = (search) => {
    setSearchData(search);
    form.setFieldsValue({ receiver: search?.formatted_address });
  };
  const handleReset = () => {
     // Đặt lại trạng thái hiển thị thông tin vận chuyển
  setShowShippingFee(false);
  form.resetFields();
  setLocateData({});
  setSearchData({});
  setIsPromoCodeEntered(false);
  };
  const handleShip = async () => {
    const fields = [
      "dimensions_height",
      "dimensions_width",
      "dimensions_depth",
      "dimensions_weight",
      "service_type",
    ];
    const {
      dimensions_weight,
      dimensions_depth,
      dimensions_width,
      dimensions_height,
      service_type,
    } = form.getFieldsValue(fields);
    const promo_code = promoCode;
    // Kiểm tra xem các trường đã được nhập đầy đủ hay không
    const isFieldsFilled = fields.every((field) => {
      const value = form.getFieldValue(field);
      return value !== undefined && value !== null && value !== "";
    });
    if (!isFieldsFilled) {
      message.error("Vui lòng nhập đầy đủ thông tin ");
      return;
    }
    try {
      const result = await store.dispatch(
        freeShip({
          id: id,
          service_type: service_type,
          dimensions_height: dimensions_height,
          dimensions_width: dimensions_width,
          dimensions_depth: dimensions_depth,
          dimensions_weight: dimensions_weight,
          origin_address: locateData?.formatted_address,
          origin_address_latitude: locateData?.locate?.lat,
          origin_address_longitude: locateData?.locate?.lng,
          destination_address: searchData?.formatted_address,
          destination_address_latitude: searchData?.search?.lat,
          destination_address_longitude: searchData?.search?.lng,
          payment_method: "CASH",
          promo_code: promo_code,
        })
      );

      if (result.error) {
        message.error(result.error.message);
      }
      if (result.payload.quotes) {
        setShowShippingFee(true);
      }
    } catch (error) {
      message.error("Lỗi tính phí vận chuyển");
    }
  };
  const createOrder = async (values) => {
    try {
      const result = await store.dispatch(
        orderGrap({
          idCart: id, // id don hang
          serviceType: values.service_type, //kieu van chuyen
          codType: "REGULAR", // loai tien mat khi giao hang
          paymentMethod: "CASH", // cach thanh toan
          height: values.dimensions_height, // chieu cao
          width: values.dimensions_width, // chieu dai
          depth: values.dimensions_depth, // chieu rong
          weight: values.dimensions_weight, // can nang
          promoCode:values.promo_code, // ma khuyen mai
          origin: {
            address: locateData?.formatted_address, // dia chi nhận hang
            coordinates: {
              latitude: locateData?.locate?.lat, // vĩ độ
              longitude: locateData?.locate?.lng, // tọa độ
            },
          },
          destination: {
            address: searchData?.formatted_address, // dia chi giao hang
            coordinates: {
              latitude: searchData?.search?.lat,
              longitude: searchData?.search?.lng,
            },
          },
          sender: {
            firstName: values.firstName,
            email: values.email,
            phone: values.phone,
            smsEnabled: true,
          },
        })
      );
      if (result.payload.status === "ALLOCATING") {
        await store.dispatch(
          updateStatusGrab({
            deliveryID: result.payload.deliveryID,
            merchantOrderID: result.payload.merchantOrderID,
            status: result.payload.status,
          })
        );
        await store.dispatch(getListOrder({ idw : settings.getCookie("user_idw"), page:'grab'}));
        message.success("Tạo đơn hàng thành công");
        setCheckId(id);
      } else {
        message.error("Có lỗi khi tạo đơn hàng");
      }
    } catch (error) {
      message.error("Tạo đơn hàng thất bại");
    }
  };
  const validateLocate = (_, value) => {
    if (!value && !form.getFieldValue("sender")) {
      // Kiểm tra giá trị đã được chọn và địa chỉ người gửi đã được nhập
      return Promise.reject(new Error("Vui lòng nhập địa chỉ người gửi"));
    } else {
      return Promise.resolve();
    }
  };
  const validateSearch = (_, value) => {
    if (!value && !form.getFieldValue("receiver")) {
      // Kiểm tra giá trị đã được chọn và địa chỉ người nhận đã được nhập
      return Promise.reject(new Error("Vui lòng nhập địa chỉ người nhận"));
    } else {
      return Promise.resolve();
    }
  };
  const handlePromoCodeChange = (e) => {
    const enteredCode = e.target.value;
    setPromoCode(enteredCode);
    setIsPromoCodeEntered(enteredCode && enteredCode.trim() !== '');
  };
  
  return (
    <>
      <Form
        style={{ maxWidth: 600 }}
        onFinish={createOrder}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <div style={{ display: "flex", gap: 16 }}>
          <Form.Item
            style={{ width: "33%" }}
            label="Tên người gửi"
            name="firstName"
            rules={[{ required: true, message: "Vui lòng nhập tên người gửi" }]}
          >
            <Input placeholder="Nhập tên người gửi " />
          </Form.Item>
          <Form.Item
            style={{ width: "33%" }}
            label="Email"
            name="email"
            rules={[{ required: true, message: "Vui lòng nhập email" }]}
          >
            <Input placeholder="Nhập email" />
          </Form.Item>
          <Form.Item
            style={{ width: "33%" }}
            label="Số điện thoại"
            name="phone"
            rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}
          >
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Form.Item
            style={{ width: "50%" }}
            name="sender"
            rules={[{ validator: validateLocate }]}
          >
            <Locate
              onLocateChange={handleLocateChange}
              locateData={locateData}
            />
          </Form.Item>
          <Form.Item
            className="receiver"
            style={{ width: "50%" }}
            label="Địa chỉ người nhận"
            name="receiver"
            rules={[{ validator: validateSearch }]}
          >
            <Search
              onSearchChange={handleSearchChange}
              searchData={searchData}
            />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Form.Item
            style={{ width: "50%" }}
            label="Phương thức vận chuyển"
            name="service_type"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn phương thức vận chuyển",
              },
            ]}
          >
            <Select
              options={[
                {
                  value: "INSTANT",
                  label: "Siêu tốc",
                },
                {
                  value: "SAME_DAY ",
                  label: "Siêu rẻ",
                },
                {
                  value: "BULK",
                  label: "Hàng số lượng lớn",
                },
              ]}
              placeholder="Phương thức vận chuyển"
            />
          </Form.Item>
          <Form.Item
            style={{ width: "50%" }}
            name="promo_code"
            label="Voucher"
            initialValue="" // Giá trị mặc định để tránh lỗi khi giá trị ban đầu là null hoặc undefined
          >
            <Input placeholder="Nhập mã khuyến mãi(nếu có)" onChange={handlePromoCodeChange} />
          </Form.Item>

        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Form.Item
            style={{ width: "50%" }}
            name="dimensions_height"
            label="Chiều cao(cm)"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập chiều cao",
              },
              {
                type: "number",
                min: 1,
                max: 10000,
                message: `Chiều cao phải nằm trong khoảng 1 đến 10000cm`,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder="Nhập chiều cao"
            />
          </Form.Item>
          <Form.Item
            style={{ width: "50%" }}
            name="dimensions_width"
            label="Chiều dài(cm)"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập chiều dài ",
              },
              {
                type: "number",
                min: 1,
                max: 10000,
                message: "Chiều dài phải nằm trong khoảng từ 1 đến 10000",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder="Nhập chiêu dài"
            />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
          }}
        >
          <Form.Item
            style={{ width: "50%" }}
            name="dimensions_depth"
            label="Chiều rộng(cm)"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập chiều rộng",
              },
              {
                type: "number",
                min: 1,
                max: 10000,
                message: "Chiều rộng phải nằm trong khoảng từ 1 đến 10000",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder="Nhập chiều rộng                           "
            />
          </Form.Item>
          <Form.Item
            style={{ width: "50%" }}
            name="dimensions_weight"
            label="Cân nặng(gram)"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập cân nặng",
              },
              {
                type: "number",
                min: 1,
                max: 20000,
                message: "Cân nặng phải nằm trong khoảng 1 đến 20000",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder="Nhập cân nặng"
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            ghost
            onClick={handleShip}
            loading={isLoading}
            icon={<DollarOutlined />}
          >
            Tính phí vận chuyển
          </Button>
        </Form.Item>
        {showShippingFee ? (
          <>
          <p style={{ color: '#389e0d' }}>
              {isPromoCodeEntered && data?.quotes[0]?.discountInfo ? (
                <>
                  Khuyến mại giảm giá từ Bota:
                  <span style={{ color: '#1677ff', fontWeight: 600 }}>
                    - {data?.quotes[0]?.discountInfo.amount.toLocaleString()} VND
                  </span>
                </>
              ) : null}
            </p>
            <p style={{ color: "#389e0d" }}>
            Tiền phí vận chuyển của bạn là :{" "}
              <span style={{ color: "#1677ff", fontWeight: 600 }}>
                {data?.quotes[0]?.amount.toLocaleString()} VND
              </span>
            </p>
            <p style={{ color: "#389e0d" }}>
              Thời gian dự kiến lấy hàng:{" "}
              <span style={{ color: "#1677ff", fontWeight: 600 }}>
                {pickup}
              </span>
            </p>
            <p style={{ color: "#389e0d" }}>
              Thời gian hoàn thành:{" "}
              <span style={{ color: "#1677ff", fontWeight: 600 }}>
                {dropoff}
              </span>
            </p>
          </>
        ) : null}

        <Form.Item shouldUpdate>
          {() => (
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoadingOrder}
                icon={<PlusCircleOutlined />}
                disabled={checkId && createGrab?.status === "ALLOCATING"}
              >
                Tạo đơn
              </Button>
              <Button
                type="primary"
                htmlType="reset"
                danger
                onClick={handleReset}
                icon={<ReloadOutlined />}
              >
                Reset
              </Button>
            </Space>
          )}
        </Form.Item>
      </Form>
    </>
  );
}
