import { Button, Form, Input, InputNumber, Select, Space, message, Checkbox, Spin, Row, Col } from "antd";
import React, { useState, useEffect  } from "react";
import Locate from "../Map/Locate";
import Search from "../Map/Search";
import { store } from "../../../../redux/configStore";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import {
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { serviceAhamove } from "../../../../redux/Ahamove/ServiceAhamove";
import { createOrderAhamove } from "../../../../redux/Ahamove/CreateOrderAhamove";
import "../../../../assets/scss/pages/transport/transport.css";
import { useNavigate } from "react-router-dom";
import SieuToc from "../../../../assets/img/SieuToc.jpg";
import AhaPro from "../../../../assets/img/AhaPro.png";

export default function FormCreate({ id, cart_id}) {
  const { isLoading, data } = useSelector(
    (state) => state.persistedReducer.ServiceAhamove
  );
  const [form] = Form.useForm();
  const [locateData, setLocateData] = useState({});
  const [searchData, setSearchData] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [optionServices, setOptionServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [initialServiceId, setInitialServiceId] = useState([]);
  const [showTransportFormItemService, setShowTransportFormItemService] = useState(false);
  const navigate = useNavigate();
  const handleLocateChange = (locate) => {
    setLocateData(locate);
    form.setFieldsValue({ sender_address: locate?.formatted_address });
  };
  const handleSearchChange = (search) => {
    setSearchData(search);
    form.setFieldsValue({ receiver_address: search?.formatted_address });
  };
  useEffect(() => {
    if (optionServices.length > 0) {
      setInitialServiceId(optionServices[0]._id);
    } else {
      setSelectedService(null);
    }
  }, [optionServices]);
  
  const handleTransportFormItemService = async () => {
    handleShip();
  };
  

  const handleReset = () => {
     // Đặt lại trạng thái hiển thị thông tin vận chuyển
    form.resetFields();
    setLocateData({});
    setSearchData({});
    setShowTransportFormItemService(false);
    setSelectedService(null);
    setIsChecked(false);
  };
  const handleShip = async () => {
    try {
      if(locateData?.formatted_address !== undefined && locateData?.formatted_address !== null && locateData?.formatted_address !== "" &&
        searchData?.formatted_address !== undefined && searchData?.formatted_address !== null && searchData?.formatted_address !== "")
      {
        const result = await store.dispatch(
          serviceAhamove({
            id: id,
            order_time: 0,
            address_send: locateData?.formatted_address,
            lat_send: locateData?.locate?.lat,
            lng_send: locateData?.locate?.lng,
            address_receive: searchData?.formatted_address,
            lat_receive: searchData?.search?.lat,
            lng_receive: searchData?.search?.lng,
          })
        );
        
        if(result.payload.status == 200){
          setOptionServices(result.payload.data);
          setShowTransportFormItemService(true);
        }
      }else{
        message.error("Vui lòng nhập đầy đủ thông tin địa chỉ!");
      }
    //   // if (result.error) {
    //   //   message.error(result.error.message);
    //   // }
    } catch (error) {
      message.error("Lỗi tính phí vận chuyển");
    }
  };
  const createOrder = async (values) => {
    try {
      const params = {};
      params.sender_address = locateData?.formatted_address;
      params.sender_lat = locateData?.locate?.lat;
      params.sender_lng = locateData?.locate?.lng;

      params.receiver_address = searchData?.formatted_address;
      params.receiver_lat = searchData?.search?.lat;
      params.receiver_lng = searchData?.search?.lng;

      params.sender_name = values.sender_name;
      params.sender_phone = values.sender_phone;
      params.items = isChecked;
      params.remarks = values.remarks;
      params.promo_code = values.promo_code;
      params.cod = values.cod;
      params.cart_id = id;
      params.service_id = values.service_id;
      params.order_time = 0;

      const result = await store.dispatch(createOrderAhamove(params));

      if(result.payload.status == 200 && result.payload.data.order_id !== undefined){
        navigate(`/detailOrderAhamove/${cart_id}`);
      }else{
        message.error(result.payload?.data?.description);
      }
    } catch (error) {
      message.error("Tạo đơn hàng thất bại");
    }
  };
  const validateLocate = (_, value) => {
    if (!value && !form.getFieldValue("sender_address")) {
      // Kiểm tra giá trị đã được chọn và địa chỉ người gửi đã được nhập
      return Promise.reject(new Error("Vui lòng nhập địa chỉ người gửi"));
    } else {
      return Promise.resolve();
    }
  };
  const validateSearch = (_, value) => {
    if (!value && !form.getFieldValue("receiver_address")) {
      // Kiểm tra giá trị đã được chọn và địa chỉ người nhận đã được nhập
      return Promise.reject(new Error("Vui lòng nhập địa chỉ người nhận"));
    } else {
      return Promise.resolve();
    }
  };
  
  return (
    <>
      <Form
        onFinish={createOrder}
        form={form}
        layout="vertical"
        name="form_create_Ahamove"
        className="popup_create_ahamove"
      >
        
        <div className="service_type_wrapper">
          <Row gutter={[16, 16]}>
              <Col span={12}>
                  <div className="selected-service">
                      {selectedService ? (
                        <>
                          <div className="selected_services_item">
                            <div className="selected_services_img">
                              <img
                                src={selectedService.icon_url}
                                alt={selectedService.name}
                                width={36}
                                height={36}
                              />
                            </div>
                            <div className="selected_services_name">
                              {`${selectedService.name} `}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
              </Col>
              <Col span={12}>
                <div className="service_type_button">
                <Button
                    type="primary"
                    onClick={handleTransportFormItemService}
                    icon={isLoading ? <Spin /> : undefined}
                    disabled={isLoading}
                    className="button_ahamove_action"
                  >
                    <span className="ahamove_btn_content">
                      <span className="group-avatars">
                        <span className="avatars"><img src={SieuToc} alt="Đổi dịch vụ" width={14} height={14} /></span>
                        <span className="avatars"><img src={SieuToc} alt="Đổi dịch vụ" width={14} height={14}  /></span>
                        <span className="avatars"><img src={AhaPro} alt="Đổi dịch vụ" width={14} height={14} /></span>
                      </span>
                      Chọn dịch vụ
                    </span>
                  </Button>
                </div>
              </Col>
          </Row>
        </div>
        <div className="popup_ahamove_form">
        <div className="popup_ahamove_form_item">
              <Form.Item
                style={{ width: "50%" }}
                name="sender_address"
                rules={[{ validator: validateLocate }]}
              >
                <Locate
                  onLocateChange={handleLocateChange}
                  locateData={locateData}
                />
              </Form.Item>
              <Form.Item
                className="receiver"
                style={{ width: "50%" }}
                label="Địa chỉ người nhận"
                name="receiver_address"
                rules={[{ validator: validateSearch }]}
              >
                <Search
                  onSearchChange={handleSearchChange}
                  searchData={searchData}
                />
              </Form.Item>
        </div>
          <>
          <div className="popup_ahamove_form_item">
              <Form.Item
                style={{ width: "50%" }}
                label="Tên người gửi"
                name="sender_name"
                rules={[{ required: true, message: "Vui lòng nhập tên người gửi" }]}
              >
                <Input placeholder="Nhập tên người gửi " />
              </Form.Item>
              <Form.Item
                style={{ width: "50%" }}
                label="Số điện thoại người gửi"
                name="sender_phone"
                rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}
              >
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >
              <Form.Item
                style={{ width: "50%" }}
                name="cod"
                label="Thu hộ"
                rules={[
                  {
                    required: false,
                    message: "Thu hộ",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Thu hộ"
                  className="cod"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                  parser={(value) => value.replace(/(,*)/g, '').replace('.', '')} 
                />
              </Form.Item>
              <Form.Item
                style={{ width: "50%" }}
                name="promo_code"
                label="Voucher"
                initialValue="" // Giá trị mặc định để tránh lỗi khi giá trị ban đầu là null hoặc undefined
              >
                <Input placeholder="Nhập mã khuyến mãi(nếu có)" />
              </Form.Item>

            </div>
            <div className="popup_ahamove_form_item">
              <Form.Item label="Ngành hàng" name="items" style={{ width: "50%" }}>
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                >
                  Ngành hàng thực phẩm
                </Checkbox>
              </Form.Item>
              <Form.Item
                style={{ width: "50%" }}
                label="Dịch vụ vận chuyển"
                name="service_id"
                className="option_services"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn dịch vụ vận chuyển",
                  },
                ]}
                hidden={!showTransportFormItemService}
              >
                <Select
                    placeholder="Dịch vụ vận chuyển"
                    onChange={(value) => {
                      const selected = optionServices.find((option) => option._id === value);
                      setSelectedService(selected);
                    }}
                    value={initialServiceId}  
                  >
                    {optionServices && optionServices.length > 0 && optionServices.map((option) => (
                      <Select.Option
                        key={option._id}
                        value={option._id}
                        disabled={!option.total_price}
                      >
                        {option.total_price ? (
                          <>
                            <span className="option_services_item">
                              <span className="option_services_name">
                                <img
                                  src={option.icon_url}  
                                  alt={option.name}  
                                  className="option_services_img"  
                                />{`${option.name} `}
                              </span>
                              <span className="option_services_price">
                                {`${option.total_price.toLocaleString('vi-VN', {
                                  style: 'currency',
                                  currency: 'VND',
                                })}`}
                              </span>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="option_services_item">
                              <span className="option_services_name">
                                <img
                                  src={option.icon_url}  
                                  alt={option.name}  
                                  className="option_services_img"  
                                /> {`${option.name} - không hỗ trợ`}
                              </span>
                            </span>
                          </>
                        )}
                      </Select.Option>
                    ))}
                  </Select>
              </Form.Item>

            </div>
            <div className="popup_ahamove_form_col">
              <Form.Item label="Ghi chú cho tài xế" name="remarks">
                  <TextArea
                    rows={4}
                    placeholder="Tối đa 5000 kí tự "
                    maxLength={5000}
                  />
              </Form.Item>
            </div>
          </>

        <Form.Item shouldUpdate>
          {() => (
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                icon={<PlusCircleOutlined />}
              >
                Tạo đơn
              </Button>
              <Button
                type="primary"
                htmlType="reset"
                danger
                onClick={handleReset}
                icon={<ReloadOutlined />}
              >
                Reset
              </Button>
            </Space>
          )}
        </Form.Item>
        </div>
      </Form>
    </>
  );
}
