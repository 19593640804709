import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { https, settings } from "../../utils/config";

const initialState = {
  accessTokenVnp: null,
  isloadTokenVnp: false,
};

export const getAccessTokenVnp = createAsyncThunk(
  "VietnamPost/getAccessTokenVnp",
  async (data) => {
    const response = await https.post("/get-token-vnpost", data);
    return response.data.data;
  }
);

const getTokenVnp = createSlice({
  name: "getTokenVnp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccessTokenVnp.pending, (state) => {
        state.isloadTokenVnp = true;
      })
      .addCase(getAccessTokenVnp.fulfilled, (state, action) => {
        state.accessTokenVnp = action.payload;
        settings.setCookie("access_token_vnp", action?.payload?.token, 7);
        state.isloadTokenVnp = false;
      })
      .addCase(getAccessTokenVnp.rejected, (state) => {
        state.isloadTokenVnp = false;
      });
  },
});
export const {} = getTokenVnp.actions;

export default getTokenVnp.reducer;
