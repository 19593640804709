import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http, settings } from '../../utils/config';

const initialState = {
    token:null,
    isLoadLoginGhn:false
}
export const connectGhn = createAsyncThunk("Giaohangnhanh/connectGhn", async (data) => {
    try {
      const response = await http.post("/login-GHN", data);
      return response.data;
    } catch (error) {
      // Xử lý lỗi
      throw new Error("Có lỗi xảy ra trong quá trình kết nối");
    }
  });
const loginGhn = createSlice({
  name: "loginGhn",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectGhn.pending, (state) => {
        state.isLoadLoginGhn = true;
      })
      .addCase(connectGhn.fulfilled, (state, action) => {
        state.isLoadLoginGhn = false;
        state.token = action.payload;
        settings.setCookie("tokenGhn",action.payload.Token,30)
      })
      .addCase(connectGhn.rejected, (state, action) => {
        state.isLoadLoginGhn = false;
      });
  },
});

// export const {} = loginGhn.actions

export default loginGhn.reducer