import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    dataAsync: null,
    isloadData: false,
    isloadProduct: false
}
export const SyncBotaSendo = createAsyncThunk(
    "products/SyncBotaSendo",
    async (data) => {
        try {
            const response = await http.post("/create-product-sendo", data);
            // console.log(response)
            return response.data;
        } catch (error) {
            throw new Error("Có lỗi xảy ra trong quá trình kết nối");
        }

    }
);
export const updateStatus = createAsyncThunk(
    "products/updateStatus",
    async (data) => {
        try {
            const response = await http.post("/update-status-product", data);
            return response.data;
        } catch (error) {
            throw new Error("Có lỗi xảy ra");
        }
    }
);
export const updateProduct = createAsyncThunk(
    "products/updateProduct",
    async (data) => {
        try {
            const response = await http.post("/update-product-sendo", data);
            return response.data;
        } catch (error) {
            throw new Error("Có lỗi xảy ra");
        }
    }
);

const productSync = createSlice({
    name: "productSync",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(SyncBotaSendo.pending, (state) => {
                state.isloadData = true;
            })
            .addCase(SyncBotaSendo.fulfilled, (state, action) => {
                state.isloadData = false;
                state.dataAsync = action.payload;
            })
            .addCase(SyncBotaSendo.rejected, (state, action) => {
                state.isloadData = false;
                // state.err = action.payload.message;

            })
            // update product 
            .addCase(updateProduct.pending, (state) => {
                state.isloadProduct = true;
            })
            .addCase(updateProduct.fulfilled, (state) => {
                state.isloadProduct = false;
            })
            .addCase(updateProduct.rejected, (state) => {
                state.isloadProduct = false;

            })
    },
});

export const { } = productSync.actions

export default productSync.reducer