import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/scss/pages/lazadaCss/HomeLazada.css";
import reportWebVitals from "./reportWebVitals";
import Body from "./components/Body";
import { Provider } from "react-redux";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Loader from "./components/Loader/Loader";
import { store } from "./redux/configStore";
import PrivativeRouterLogin from "./components/PrivativeRouterLogin";
import PrivativeCheckLogin from "./components/PrivativeCheckLogin";
import PrivativeRouter from "./components/PrivativeRouter";
import PrivateRouterShopee from "./components/PrivateRouterShopee";
import PrivativeRouterLazada from "./components/PrivativeRouterLazada";
import Callback_lazada from "./pages/Content/Lazada/Callback_lazada";
import PrativeRouterVnp from "./components/PrativeRouterVnp";
import PrativeRouterViettelpost from "./components/PrativeRouterViettelpost";
import PrivativeRouterAhamove from "./components/PrivativeRouterAhamove";
import Login from "./pages/Login/Login";
import Page404 from "./pages/404Page/Page404";
import PrativeGhn from "./components/PrativeGhn";
import LoadDatabase from "./pages/Login/LoadDatabase";
import HomeGrap from "./pages/Content/GrapExpress/HomeGrap";
import HomeAhamove from "./pages/Content/Ahamove/HomeAhamove";
import HomeViettelPost from "./pages/Content/ViettelPost/HomeViettelPost";
import LogoutGrap from "./pages/Content/GrapExpress/Logout/LogoutGrap";
import PrivateRouterGrap from "./components/PrivateRouterGrap";
import SuccessGrap from "./pages/Content/GrapExpress/SuccessGrap";
import SuccessAhamove from "./pages/Content/Ahamove/SuccessAhamove";
const DetailGrab = React.lazy(() => import("./pages/Content/GrapExpress/DetailOrder/DetailGrab"));
const DetailAhamove = React.lazy(() => import("./pages/Content/Ahamove/DetailOrder/DetailAhamove"));
const LogoutGhn = React.lazy(() => import("./pages/Content/GiaoHangNhanh/LogoutGhn/LogoutGhn"));
const SyncShopee = React.lazy(() =>
  import("./pages/Content/Shopee/SyncShopee")
);
const Logout = React.lazy(() => import("./pages/Content/Sendo/Logout/Logout"));
const CreateOrder = React.lazy(() =>
  import("./pages/Content/GiaoHangNhanh/CreateOrder/CreateOrder")
);
const CreateOrderVnp = React.lazy(() =>
  import("./pages/Content/VietnamPost/CreateOrderVnp/CreateOrderVnp")
);
const LogoutVnp = React.lazy(() =>
  import("./pages/Content/VietnamPost/LoguotVnp/LogoutVnp")
);
const CreateShopee = React.lazy(() =>
  import("./pages/Content/Shopee/CreateShopee")
);
const HomeGhn = React.lazy(() =>
  import("./pages/Content/GiaoHangNhanh/HomeGhn")
);
const ConnectGhn = React.lazy(() =>
  import("./pages/Content/GiaoHangNhanh/ConnectGhn")
);
const PostProduct = React.lazy(() =>
  import("./pages/Content/Sendo/PostProduct/PostProduct")
);
const ConnectVnp = React.lazy(() =>
  import("./pages/Content/VietnamPost/ConnectVnp")
);
const ConnectViettelpost = React.lazy(() =>
  import("./pages/Content/ViettelPost/ConnectViettelpost")
);
const LogoutViettelpost = React.lazy(() =>
  import("./pages/Content/ViettelPost/LogoutViettelpost/LogoutViettelpost")
);
const ConnectAhamove = React.lazy(() =>
  import("./pages/Content/Ahamove/ConnectAhamove")
);
const LogoutAhamove = React.lazy(() =>
  import("./pages/Content/Ahamove/Logout/LogoutAhamove")
);
const Homebody = React.lazy(() => import("./pages/Content/Homebody"));
const HomeSendo = React.lazy(() => import("./pages/Content/Sendo/HomeSendo"));
const Dashboard = React.lazy(() => import("./pages/Content/Dashboard"));
const Homeorder = React.lazy(() => import("./pages/Content/Homeorder"));
// route lazada
const PostProductLazada = React.lazy(() =>
  import("./pages/Content/Lazada/PostProduct/PostProductLazada")
);

const Notification = React.lazy(() =>
  import("./pages/Content/Lazada/Notification")
);
const Logoutlazada = React.lazy(() => import("./pages/Logout/LogoutLazada"));
const Disconnect = React.lazy(() =>
  import("./pages/Content/Shopee/Disconnect/Disconnect")
);

const RedirectTiktok = React.lazy(() =>
  import("./pages/Content/TikTok/RedirectTiktok")
);
const SyncTiktok = React.lazy(() =>
  import("./pages/Content/TikTok/SyncTiktok")
);
const DisconnectTikTok = React.lazy(() =>
  import("./pages/Content/TikTok/Disconnect/DisconnectTikTok")
);
const PrivateRouterTikTok = React.lazy(() =>
  import("./components/PrivateRouterTikTok")
);
export const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path='' element={<PrivativeRouterLogin />}>
            <Route path='' element={<Body />}>
              <Route path='*' element={<Page404 />}></Route>
              <Route index element={<Dashboard />} />
              <Route path='/ecommerce' element={<Homebody />} />
              <Route path='/order' element={<Homeorder />} />
              <Route path='/vanchuyen' element={<HomeGhn />} />
              <Route path='/connectGhn' element={<ConnectGhn />} />
              <Route path='/ConnectViettelpost' element={<ConnectViettelpost />} />
              <Route path='/ConnectAhamove' element={<ConnectAhamove />} />
              <Route path='/connectSendo' element={<HomeSendo />} />
              <Route path='' element={<PrivativeRouter />}>
                <Route path='/create-product' element={<PostProduct />} />
                <Route path='/settings' element={<Logout />} />
              </Route>
              {/* lazada */}
              <Route
                path='/install/callback_lazada'
                element={<Callback_lazada />}
              />
              <Route path='' element={<PrivativeRouterLazada />}>
                <Route
                  path='/add-product-lazada'
                  element={<PostProductLazada />}
                />
                <Route path='/notification' element={<Notification />} />
                <Route path='/logout-lazada' element={<Logoutlazada />} />
              </Route>
              {/* lazada close */}
              <Route path='' element={<PrativeGhn />}>
                <Route path='/create-order' element={<CreateOrder />} />
                <Route path='/logoutGhn' element={<LogoutGhn />} />
              </Route>
              <Route path="" element={<PrivateRouterGrap />}>
                <Route path="/create-order-grap" element={<HomeGrap />} />
                <Route path='/detailOrder'>
                  <Route path=":id" element={<DetailGrab />}></Route>
                </Route>
                <Route path="/successGrap" element={<SuccessGrap />} />
                <Route path="/logoutGrap" element={<LogoutGrap />} />
              </Route>
              <Route path="" element={<PrivativeRouterAhamove />}>
                <Route path="/create-order-Ahamove" element={<HomeAhamove />} />
                <Route path='/detailOrderAhamove'>
                  <Route path=":id" element={<DetailAhamove />}></Route>
                </Route>
                <Route path="/successAhamove" element={<SuccessAhamove />} />
                <Route path="/logoutAhamove" element={<LogoutAhamove />} />
              </Route>
              <Route path='/connectVnp' element={<ConnectVnp />} />
              <Route path='' element={<PrativeRouterVnp />}>
                <Route path='/create-order-vnp' element={<CreateOrderVnp />} />
                <Route path='/LogoutVnp' element={<LogoutVnp />} />
              </Route>
              <Route path='/ConnectViettelpost' element={<ConnectViettelpost />} />
              <Route path='' element={<PrativeRouterViettelpost />}>
                <Route path='/create-order-Viettelpost' element={<HomeViettelPost />} />
                <Route path='/LogoutViettelpost' element={<LogoutViettelpost />} />
              </Route>
              <Route path='/sync-shopee' element={<CreateShopee />} />
              <Route path='' element={<PrivateRouterShopee />}>
                <Route path='/new-sync-shopee' element={<SyncShopee />} />
                <Route path='/logout' element={<Disconnect />} />
              </Route>
              <Route path='/redirect-tiktok' element={<RedirectTiktok />} />
              <Route path='' element={<PrivateRouterTikTok />}>
                <Route path='/sync-Tiktok' element={<SyncTiktok />} />
                <Route path='/logoutTiktok' element={<DisconnectTikTok />} />
              </Route>
            </Route>
            <Route path='/ConnectViettelpost' element={<ConnectViettelpost />} />
          </Route>

          <Route path='' element={<PrivativeCheckLogin />}>
            <Route path='/login' element={<Login />} />
            <Route path='/loadata' element={<LoadDatabase />} />
          </Route>
        </Routes>
      </Suspense>
    </HistoryRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
