import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    datas: null,
    loadbota: false,

}
export const getProductBota = createAsyncThunk(
    'product/getProductBota',
    async (idweb) => {
        try {
            const response = await http.get(
                `/show-list-product-sendo?idw=${idweb}`
            );
            return response.data;
        } catch (error) {
            throw new Error("Có lỗi xảy ra trong quá trình kết nối");
        }
    }
);
const listProductBota = createSlice({
    name: "listProductBota",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductBota.pending, (state) => {
                state.loadbota = true;
            })
            .addCase(getProductBota.fulfilled, (state, action) => {
                state.datas = action.payload
                state.loadbota = false;
            })
            .addCase(getProductBota.rejected, (state, action) => {
                state.loadbota = false;
                // state.eroi = action.error.message;
            })


    },
});

export const {} = listProductBota.actions

export default listProductBota.reducer