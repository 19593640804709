import React, { useEffect } from "react";
import NProgress from "nprogress";
import { useNavigate } from "react-router-dom";
import "nprogress/nprogress.css";
import { Spin } from "antd";
export default function Loader() {
  const navigate = useNavigate();
  useEffect(() => {
    NProgress.start();
    NProgress.inc();
    NProgress.configure({
      showSpinner: true,
      easing: "ease",
      speed: 700,
      trickleSpeed: 500,
    });
    NProgress.done();
  }, [navigate]);
  return (
    <div className="loader">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color:"#73d13d"
        }}
      >
        <Spin size="large"></Spin>
      </div>
    </div>
  );
}
