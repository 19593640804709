import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAccessTokenLazada } from "../../../redux/Lazada/UrlLazada";
import { Result, message } from "antd";
import { settings } from "../../../utils/config";
import {
  getNameUserLazada,
  getCreateUserLazada,
} from "../../../redux/Lazada/UrlgetSellerLazada";

import Loading from "./Loading";

export default function Callback_lazada() {
  const { isLoadTokenLazada } = useSelector(
    (state) => state.persistedReducer.UrlLazada
  );
  const { isLoaduser, isLoadCreateLazada } = useSelector(
    (state) => state.persistedReducer.UrlgetSellerLazada
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const codeLazada = searchParams.get("code");

  useEffect(() => {
    const createToken = async () => {
      try {
        const result = await dispatch(getAccessTokenLazada(codeLazada));
        if (result?.payload?.access_token) {
          const accessTokenLazada = result.payload.access_token;
          const result1 = await dispatch(getNameUserLazada(accessTokenLazada));
          if (result1?.payload) {
            let formData = {};
            formData.shop_id = result1.payload.short_code;
            formData.shop_name = result1.payload.name;
            formData.seller_id = result1.payload.seller_id;
            const result2 = await dispatch(getCreateUserLazada(formData));
            if (result2?.payload.status == 200) {
              message.success(`Kết nối thành công cửa hàng mới`);
              navigate("/notification");
            } else if (result2?.payload.status == 400) {
              message.success(`Kết nối thành công cửa hàng`);
              navigate("/add-product-lazada");
            }
          } else {
            message.error("Lỗi chuyển hướng cửa hàng");
          }
        } else {
          message.error("Lỗi chuyển hướng");
        }
      } catch (error) {
        message.error("Có lỗi khi chuyển hướng");
      }
    };
    createToken();
  }, codeLazada);

  return (
    <>
      {isLoadTokenLazada || isLoadCreateLazada || isLoaduser ? (
        <Loading />
      ) : (
        <div>Required String parameter 'code' is not present</div>
      )}
    </>
  );
}
