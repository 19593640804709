import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { http } from '../../utils/config';

export const getServiceViettelPost = createAsyncThunk(
  'ViettelPost/getServiceViettelPost',
  async () => {
    const response = await http.post(`/get-list-service-viettelpost`);
    return response.data.data;
  }
);

const serviceViettelPostSlice = createSlice({
  name: 'serviceViettelPost',
  initialState: {
    isFetching: false,
    servicelist: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServiceViettelPost.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getServiceViettelPost.fulfilled, (state, action) => {
        state.servicelist = action.payload.data || [];
        state.isFetching = false;
      })
      .addCase(getServiceViettelPost.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.error.message;
      });
  },
});

export default serviceViettelPostSlice.reducer;
