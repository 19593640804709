import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    isLoadfeeShip: false,
    result: null,
    time: null,
    isLoadTime: false
}
export const feeShipGhn = createAsyncThunk(
    'Giaohangnhanh/feeShipGhn',
    async (data) => {
        const response = await http.post(
            `/service-fee`, data
        );
        return response.data.data;
    }
);
export const expectedDeliveryTime = createAsyncThunk(
    'Giaohangnhanh/expectedDeliveryTime',
    async (data) => {
        const response = await http.post(
            `/get-expected-delivery-time`, data
        );
        console.log(response)
        return response.data.data;
    }
);

const chargeShipping = createSlice({
    name: "chargeShipping",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(feeShipGhn.pending, (state) => {
                state.isLoadfeeShip = true;
            })
            .addCase(feeShipGhn.fulfilled, (state, action) => {
                state.isLoadfeeShip = false;
                state.result = action.payload;
            })
            .addCase(feeShipGhn.rejected, (state, action) => {
                state.isLoadfeeShip = false;
                // state.err = action.payload.message;

            })
            //thời gian giao hàng dự kiến
            .addCase(expectedDeliveryTime.pending, (state) => {
                state.isLoadTime = true;
            })
            .addCase(expectedDeliveryTime.fulfilled, (state, action) => {
                state.isLoadTime = false;
                state.time = action.payload;
            })
            .addCase(expectedDeliveryTime.rejected, (state, action) => {
                state.isLoadTime = false;
                // state.err = action.payload.message;

            })


    },
});

// export const { } = chargeShipping.actions

export default chargeShipping.reducer