import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import connectSendo from "./user/connectSendo";
import listCategory from "./products/listCategory";
import listProductBota from "./products/listProductBota";
import productSync from "./products/productSync";
import warehouseList from "./Giaohangnhanh/warehouseList";
import getTheServicePack from "./Giaohangnhanh/getTheServicePack";
import getPickUpList from "./Giaohangnhanh/getPickUpList";
import CreateOrderGhn from "./Giaohangnhanh/CreateOrderGhn";
import chargeShipping from "./Giaohangnhanh/chargeShipping";
import listProductOrder from "./Giaohangnhanh/listProductOrder";
import getUrl from "./Shopee/getUrl";
import getCategory from "./Shopee/getCategory";
import getUrlTikTok from "./TikTok/getUrlTikTok";
import getCateGory from "./TikTok/getCateGory";
import getCateGoryRules from "./TikTok/getCateGoryRules";
import getProductBotaV2 from "./TikTok/getProductBotaV2";
import upLoadImg from "./TikTok/upLoadImg";
import syncTikTok from "./TikTok/syncTikTok";
import updateStatusTiktok from "./TikTok/updateStatusTiktok";


// lazada
import connectLazada from "./user/connectLazada";
import UrlLazada from "./Lazada/UrlLazada";
import UrlgetSellerLazada from "./Lazada/UrlgetSellerLazada";
import UrlgetCategoryLazada from "./Lazada/UrlgetCategoryLazada";
import UrlSyncLazada from "./Lazada/UrlSyncLazada";

// VietnamPost
import ListOrderVnp from "./VietnamPost/ListOrderVnp";
import getAddress from "./VietnamPost/getAddress";
import getTokenVnp from "./VietnamPost/getTokenVnp";
import getOrderVnp from "./VietnamPost/getOrderVnp";

//ViettelPost
import getWarehouse from "./ViettelPost/getWarehouse";
import warehouseSlice from "./ViettelPost/getWarehouse";

//login
import Login from "./Login/Login";
import loginGhn from "./Giaohangnhanh/loginGhn";
import LoginGrap from "./GrapExpress/LoginGrap";
import FreeShipGrap from "./GrapExpress/FreeShipGrap";
import CreateOrderGrap from "./GrapExpress/CreateOrderGrap";
import DetailGrabOrder from "./GrapExpress/DetailGrabOrder";

//Ahamove
import DetailAhamoveOrder from "./Ahamove/DetailAhamoveOrder";
import TrackingURLAhamove from "./Ahamove/TrackingURLAhamove";
import ServiceAhamove from "./Ahamove/ServiceAhamove";

const persistConfig = {
  key: "data",
  storage,
  whitelist: ["listProductBota"],
};
export const rootReducers = combineReducers({
  connectSendo,
  listCategory,
  listProductBota,
  productSync,
  warehouseList,
  getTheServicePack,
  getPickUpList,
  CreateOrderGhn,
  chargeShipping,
  listProductOrder,
  loginGhn,
  // lazada
  connectLazada,
  UrlLazada,
  UrlgetSellerLazada,
  UrlgetCategoryLazada,
  UrlSyncLazada,

  // VietnamPost
  ListOrderVnp,
  getAddress,
  getTokenVnp,
  getOrderVnp,

  // Login
  Login,

  //Shopee
  getUrl,
  getCategory,
  //Tiktok
  getUrlTikTok,
  getCateGory,
  getCateGoryRules,
  getProductBotaV2,
  upLoadImg,
  syncTikTok,
  updateStatusTiktok,
  //GrapExpress
  LoginGrap,
  FreeShipGrap,
  CreateOrderGrap,
  DetailGrabOrder,
  
  //Viettelpost
  getWarehouse,
  warehouseSlice,

  //Ahamove
  DetailAhamoveOrder,
  TrackingURLAhamove,
  ServiceAhamove
});
const persistedReducer = persistReducer(persistConfig, rootReducers);
export const store = configureStore({
  reducer: { persistedReducer },
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});
export const persistor = persistStore(store);
