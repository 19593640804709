import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    categoryTiktok: null,
    isLoadCategory: false
}
export const getCateGoryTikTok = createAsyncThunk(
    'TikTok/getCateGoryTikTok',
    async (token) => {
        const response = await http.get(
            `/get-category-tiktok?access_token=${token}`
        );
        return response.data.data;
    }
);
const getCateGory = createSlice({
    name: "getCateGory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCateGoryTikTok.pending, (state) => {
                state.isLoadCategory = true;
            })
            .addCase(getCateGoryTikTok.fulfilled, (state, action) => {
                state.categoryTiktok = action.payload
                state.isLoadCategory = false;
            })
            .addCase(getCateGoryTikTok.rejected, (state, action) => {
                state.isLoadCategory = false;
            })



    },
});

export const { } = getCateGory.actions

export default getCateGory.reducer