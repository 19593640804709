import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    data: null,
    isLoad: false
}
export const detailGrab = createAsyncThunk("GrapExpress/detailGrab", async (sku) => {
    try {
        const response = await http.get(`/deliveries-detail-grab?id=${sku}`);
        return response.data.data;
    } catch (error) {
        throw new Error("Có lỗi xảy ra");
    }
});
const DetailGrabOrder = createSlice({
    name: "DetailGrabOrder",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(detailGrab.pending, (state) => {
                state.isLoad = true;
            })
            .addCase(detailGrab.fulfilled, (state, action) => {
                state.isLoad = false;
                state.data = action.payload;
            })
            .addCase(detailGrab.rejected, (state, action) => {
                state.isLoad = false;
                // state.err = action.payload.message;

            })
    },
});

// export const { } = DetailGrabOrder.actions

export default DetailGrabOrder.reducer