import { Button, Result, Space } from "antd";
import React from "react";
import background from "../../../assets/img/background.jpg";
import logo_bota from "../../../assets/img/logo_bota.png";
import grapexpress from "../../../assets/img/grapexpress.png";
import { RetweetOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
export default function SuccessGrap() {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
      }}
    >
      <div style={{ padding: 50 }}>
        <Space size={30}>
          <img src={logo_bota} alt="" />
          <RetweetOutlined style={{ fontSize: "70px", color: "#1677ff" }} />
          <img
            src={grapexpress}
            alt=""
            style={{ width: "200px", verticalAlign: "sub" }}
          />
        </Space>
        <div style={{ display: "flex" }}>
          <Result
            status="success"
            title="Bạn đã kết nối thành công GrapExpress"
            extra={
              <NavLink to={"/create-order-grap"}>
                <Button>Đến danh sách đơn hàng</Button>
              </NavLink>
            }
          />
        </div>
      </div>
    </div>
  );
}
