import { Button, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { LoadDataCart, LoadDataProduct } from "../../redux/Login/Login";
import { NavLink, useNavigate,useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header } from "antd/es/layout/layout";
import logo_bota from "../../assets/img/logo_bota.png";
import LogoB from "../../assets/img/LogoB.png";
import loading from "../../assets/img/loading.png";
import { settings } from "../../utils/config";
import { ArrowLeftOutlined } from "@ant-design/icons";
const headerStyle = {
  color: "#fff",
  height: 64,
  paddingInline: 50,
  lineHeight: "64px",
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 4px",
  position: "fixed",
  width: "100%",
};
export default function LoadDatabase() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //load user_token
  const location = useLocation();

  const idw = settings.getCookie("user_idw");
  // console.log(isLoading)
  useEffect(() => {
    //load user_token
    const queryParams = new URLSearchParams(location.search);
    const userToken = queryParams.get("user_token");
    const userIdw = queryParams.get("user_idw");

    const fetchData = async () => {
      setIsLoading(true);
      try {
        //load user_token
        // Nếu userToken tồn tại, thiết lập cookie
        if (userToken) {
          settings.setCookie("user_token", userToken, 1); // 1: Số ngày cookie tồn tại
          settings.setCookie("user_idw", userIdw, 1);
          // Load lại trang sau khi set cookie
          // window.location.reload();
        }
        const [result, result2] = await Promise.all([
          dispatch(LoadDataProduct({ idw: idw })),
          dispatch(LoadDataCart({ idw: idw })),
        ]);
        setIsLoading(false);
        // Kiểm tra điều kiện trước khi chuyển hướng
        if (result.payload.status === 200 && result2.payload.status === 200) {
          navigate("/");
          message.success("Đăng nhập thành công");
        }
      } catch {
        setIsLoading(false);
        // Xử lý lỗi nếu cần
      }
    };
    fetchData();
  }, [dispatch, idw, navigate, location.search]);
  return (
    <>
      <Header style={headerStyle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={logo_bota}
            alt="logo"
            style={{ width: "117px", marginRight: 68 }}
          ></img>
          <NavLink style={{ color: "#6D6B6B", fontSize: 24 }}>
            (+84) 1900 2008
          </NavLink>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 44,
            alignItems: "center",
          }}
        >
          <NavLink
            to={"https://bota.vn/ve-chung-toi/"}
            target="_blank"
            style={{ fontSize: 16, color: "#424242", fontWeight: 700 }}
          >
            Về chúng tôi
          </NavLink>
          <NavLink
            to={"https://bota.vn/support/"}
            target="_blank"
            style={{ fontSize: 16, color: "#424242", fontWeight: 700 }}
          >
            Hỗ trợ{" "}
          </NavLink>
          <NavLink
            to={"https://bota.vn/lien-he/"}
            target="_blank"
            style={{ fontSize: 16, color: "#424242", fontWeight: 700 }}
          >
            Liên hệ{" "}
          </NavLink>
        </div>
      </Header>
      <div
        style={{
          backgroundImage: `url(${loading})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: 183,
          height: "100vh",
        }}
      >
        <section style={{ display: "flex", justifyContent: "center" }}>
          <img src={LogoB} alt="" />
        </section>
        <div
          style={{
            marginTop: 100,
            textAlign: "center",
            color: "#636363",
            fontSize: 16,
            fontWeight: 700,
          }}
        >
          {isLoading ? (
            <div>
              <p>Đang tải dữ liêu vui lòng đợi... </p>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <p>Tải dữ liêu thành công </p>
              <NavLink to={"/"}>
                <Button type="primary" icon={<ArrowLeftOutlined />}>
                  Quay lại trang quản trị
                </Button>
              </NavLink>
            </>
          )}
        </div>
      </div>
    </>
  );
}
