import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../utils/config";

const initialState = {
  options: [],
  option2: [],
  option3: [],
  loading: false,
  loading2: false,
  loading3: false,
  error: null,
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const response = await http.get("/list-category?id=0");
    // console.log(response);
    return response.data.data.result;
  }
);
export const fetchSubcategories = createAsyncThunk(
  "categories/fetchSubcategories",
  async (categoryId) => {
    const response = await http.get(`/list-category?id=${categoryId}`);
    return response.data.data.result;
  }
);
export const fetchSubcategories3 = createAsyncThunk(
  "categories/fetchSubcategories3",
  async (categoryId) => {
    const response = await http.get(`/list-category?id=${categoryId}`);
    return response.data.data.result;
  }
);

const listCategory = createSlice({
  name: "listCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Get api category cấp 1
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.options = action?.payload?.map((category) => ({
          value: category.id,
          label: category.name,
          // isLeaf: category.level === 2,
        }));

        state.loading = false;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      //Get api category cấp 2
      .addCase(fetchSubcategories.pending, (state) => {
        state.loading2 = true;
      })
      .addCase(fetchSubcategories.fulfilled, (state, action) => {
        state.option2 = action?.payload?.map((category) => ({
          value: category.id,
          label: category.name,
          // isLeaf: category.level === 3,
        }));
        state.loading2 = false;
      })
      .addCase(fetchSubcategories.rejected, (state, action) => {
        state.loading2 = false;
        state.error = action.error.message;
      })
      //Get api category cấp 3
      .addCase(fetchSubcategories3.pending, (state) => {
        state.loading3 = true;
      })
      .addCase(fetchSubcategories3.fulfilled, (state, action) => {
        state.option3 = action?.payload?.map((category) => ({
          value: category.id,
          label: category.name,
          // isLeaf: category.level === 4,
        }));
        state.loading3 = false;
      })
      .addCase(fetchSubcategories3.rejected, (state, action) => {
        state.loading3 = false;
        state.error = action.error.message;
      });
  },
});

export const {} = listCategory.actions;

export default listCategory.reducer;
