import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http, settings } from '../../utils/config';

const initialState = {
    img: null,
    isLoadImg: false
}
export const upLoadImgTiktok = createAsyncThunk(
    'TikTok/upLoadImgTiktok',
    async (img) => {
        const response = await http.get(
            `/upload-imgs-tiktok?access_token=${settings.getCookie("access_tokenTikTok")}&img_data=${img}`
        );
        // console.log(response)
        return response.data.data.data;
    }
);
const upLoadImg = createSlice({
    name: "upLoadImg",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(upLoadImgTiktok.pending, (state) => {
                state.isLoadImg = true;
            })
            .addCase(upLoadImgTiktok.fulfilled, (state, action) => {
                state.img = action.payload
                state.isLoadImg = false;
            })
            .addCase(upLoadImgTiktok.rejected, (state, action) => {
                state.isLoadImg = false;
            })



    },
});

export const { } = upLoadImg.actions

export default upLoadImg.reducer