import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httplazada } from "../../utils/config";

const initialState = {
  shippingFeeVnp: null,
  isloadShippingFee: false,
  isloadPostOrderVnp: false,
};

export const getShippingFee = createAsyncThunk(
  "VietnamPost/getShippingFee",
  async (data) => {
    const response = await httplazada.post("/services-charge-vnpost", data);
    return response.data.data;
  }
);
export const postOrderVnp = createAsyncThunk(
  "VietnamPost/postOrderVnp",
  async (data) => {
    const response = await httplazada.post("/order-vnpost", data);
    return response.data;
  }
);

const getOrderVnp = createSlice({
  name: "getOrderVnp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShippingFee.pending, (state) => {
        state.isloadShippingFee = true;
      })
      .addCase(getShippingFee.fulfilled, (state, action) => {
        state.shippingFeeVnp = action.payload;
        state.isloadShippingFee = false;
      })
      .addCase(getShippingFee.rejected, (state) => {
        state.isloadShippingFee = false;
      })
      .addCase(postOrderVnp.pending, (state) => {
        state.isloadPostOrderVnp = true;
      })
      .addCase(postOrderVnp.fulfilled, (state, action) => {
        state.isloadPostOrderVnp = false;
      })
      .addCase(postOrderVnp.rejected, (state) => {
        state.isloadPostOrderVnp = false;
      });
  },
});

export const {} = getOrderVnp.actions;

export default getOrderVnp.reducer;
