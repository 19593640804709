import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACCESSTOKEN_LAZADA, httplazada, settings } from "../../utils/config";

const initialState = {
  linkLazada: null,
  tokenLazada: null,
  isLoadTokenLazada: false,
};

export const getLoginLazada = createAsyncThunk(
  "Lazada/getLoginLazada",
  async () => {
    const response = await httplazada.get(`/connect-lazada`);
    return response.data.data;
  }
);
export const getAccessTokenLazada = createAsyncThunk(
  "Lazada/getAccessTokenLazada",
  async (codeLazada) => {
    const response = await httplazada.post(`/accessToken-lazada`, {
      code: codeLazada,
    });
    return response.data.data;
  }
);

const UrlLazada = createSlice({
  name: "UrlLazada",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoginLazada.pending, (state) => {
        state.isLoadUrlTikTok = true;
      })
      .addCase(getLoginLazada.fulfilled, (state, action) => {
        state.linkLazada = action.payload;
        state.isLoadUrlTikTok = false;
      })
      .addCase(getLoginLazada.rejected, (state, action) => {
        state.isLoadUrlTikTok = false;
      })
      //Tokenn
      .addCase(getAccessTokenLazada.pending, (state) => {
        state.isLoadTokenLazada = true;
      })
      .addCase(getAccessTokenLazada.fulfilled, (state, action) => {
        state.tokenLazada = action.payload;
        state.isLoadTokenLazada = false;
        if (action?.payload.access_token) {
          settings.setCookie(
            ACCESSTOKEN_LAZADA,
            action.payload.access_token,
            7
          );
          settings.setCookie(
            "refresh_token_lazada",
            action.payload.refresh_token,
            30
          );
        }
      })
      .addCase(getAccessTokenLazada.rejected, (state, action) => {
        state.isLoadTokenLazada = false;
      });
  },
});

export default UrlLazada.reducer;
