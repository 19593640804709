import {
  Button,
  Col,
  Divider,
  Image,
  Input,
  Row,
  Space,
  Table,
  Tooltip,
  message,
  Modal
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import formatDateTime from "../../../utils/dateTime";
import { useDispatch, useSelector } from "react-redux";
import {
  EyeInvisibleTwoTone,
  EyeTwoTone,
  RightOutlined,
  SearchOutlined,
  SyncOutlined
} from "@ant-design/icons";
import { store } from "../../../redux/configStore";
import { getListOrder } from "../../../redux/Giaohangnhanh/listProductOrder";
import { settings } from "../../../utils/config";
import CreateOrder from "./ModalAhamove/CreateOrder";
import { useNavigate } from "react-router-dom";
import { LoadDataV2 } from "../../../redux/Login/Login";
import { setHasFetchedData } from "../../../redux/GrapExpress/CreateOrderGrap";
import "../../../../src/assets/scss/pages/Grabcss/Coupon.css";
export default function HomeAhamove() {
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isCopieds, setIsCopieds] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { listOrder, isLoadOrder } = useSelector(
    (state) => state.persistedReducer.listProductOrder
  );
  const dispatch = useDispatch();
  const { hasFetchedData } = useSelector(
    (state) => state.persistedReducer.CreateOrderGrap
  );
  const isSSL = window.location.protocol === "https:";
  const navigate = useNavigate();
  const searchRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        await store.dispatch(getListOrder({ idw : settings.getCookie("user_idw"), page:'ahamove'}));
        dispatch(setHasFetchedData(true));
      } catch (error) {
        message.error("Có lỗi xảy ra không tồn tại dữ liệu ");
      }
    };

    if (!hasFetchedData) {
      fetchData();
    }
  }, [hasFetchedData, dispatch]);
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    const data = listOrder?.map((items, index) => {
      const itemsData = items.productofcart.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: parseFloat(item.price),
        image: item.image,
      }));
      const formattedDateTime = formatDateTime(items.created_at);
      let statusStyle = {};
      let statusAhamove = items.notification_status;
      if (items.status_ahamove === "new") {
        statusStyle = { color: "#bfbfbf", fontWeight: 500 };
      } else if (items.status_ahamove === "IDLE") {
        statusStyle = { color: "#1677ff", fontWeight: 500 };
      } else if (items.status_ahamove === "ASSIGNING") {
        statusStyle = { color: "#fadb14", fontWeight: 500 };
      } else if (items.status_ahamove === "ACCEPTED") {
        statusStyle = { color: "#fadb14", fontWeight: 500 };
      } else if (items.status_ahamove === "IN PROCESS") {
        statusStyle = { color: "#fadb14", fontWeight: 500 };
      } else if (items.status_ahamove === "CANCELLED") {
        statusStyle = { color: "#f5222d", fontWeight: 500 };
      } else if (items.status_ahamove === "COMPLETED") {
        statusStyle = { color: "#52c41a", fontWeight: 500 };
      } else if (items.status_ahamove === "FAILED") {
        statusStyle = { color: "#f5222d", fontWeight: 500 };
      } else if (items.status_ahamove === "RETURNED") {
        statusStyle = { color: "#f5222d", fontWeight: 500 };
      }
      return {
        key: index + 1,
        id: items.id,
        invoice: items.invoice,
        cart_id: items.cart_id,
        info_buy: items.info_buy.name,
        phone: items.info_buy.phone,
        infoAddress: items.info_buy,
        status_ghn: items.status_ghn,
        total: items.total.toLocaleString(),
        address: items.info_buy.address,
        created_at: formattedDateTime,
        status: items.status_ahamove,
        status_ahamove: <span style={statusStyle}>{statusAhamove}</span>,
        items: itemsData,
      };
    });
    searchRef.current = data;
    setFilteredData(data);
  }, [listOrder]);
  const handleDetail = async (sku) => {
    navigate(`/detailOrderAhamove/${sku}`);
  };
  const handleSearch = () => {
    const results = searchRef.current?.filter((entry) =>
      Object.values(entry).some((value) =>
        value?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredData(results);
  };
  const handleReset = () => {
    setSearchText("");
    setFilteredData(searchRef.current);
  };

  //cập nhật đơn hàng mới
  const [isLoading, setIsLoading] = useState(false);
  const idw = settings.getCookie("user_idw");
  const loadDataView = () => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const [result] = await Promise.all([
          dispatch(LoadDataV2({ idw: idw, page: 'ahamove' })),
        ]);
        if (result.payload.status === 200) {
          await store.dispatch(getListOrder({ idw : idw, page:'ahamove'}));
          dispatch(setHasFetchedData(true));
          setIsLoading(false);
          message.success("Cập nhật thành công");
        }
      } catch {
        setIsLoading(false);
        message.error("Có lỗi xảy ra");
      }
    }
    fetchData();
  };
  const handleCopy = (coupon) => {
    if (coupon === "NHANH30PHUT") {
      navigator.clipboard.writeText(coupon);
      setIsCopied(true);
    } else if (coupon === "BOTATHANG3") {
      navigator.clipboard.writeText(coupon);
      setIsCopieds(true);
    }
    setTimeout(() => {
      if (coupon === "NHANH30PHUT") {
        setIsCopied(false);
      } else if (coupon === "BOTATHANG3") {
        setIsCopieds(false);
      }
    }, 3000);
  };
  const handleConditionClick = (coupon) => {
    if (coupon === "NHANH30PHUT") {
      const couponHtml = `
      <div class="coupon-row">
        <div class="coupon-label">Mã khuyến mãi:</div><span class="code">GIAO30PHUT</span>
      </div>
      <div class="coupon-row">
        <div class="coupon-label">Điều kiện:</div><div class="coupon-info">
        - Thời gian ưu đãi: Từ 11/3/2024 - 31/03/2024 <br />
        - Đối tượng áp dụng: Đồng giá 15k cho đơn hàng, giảm tối đa 10k, cho khách hàng mới lần đầu sử dụng <br />
        - Dịch vụ áp dụng: Siêu tốc - đồ ăn, Siêu tốc, 2H - public. <br />
        - Khu vực áp dụng: TP. Hồ Chí Minh, TP. Hà Nội
        </div>
      </div>
    `;
     setModalContent(couponHtml);
      setModalVisible1(true);
    } else if (coupon === "BOTATHANG3") {
      const couponHtml = `
      <div class="coupon-row">
        <div class="coupon-label">Mã khuyến mãi:</div><span class="code">BOTATHANG3</span>
      </div>
      <div class="coupon-row">
        <div class="coupon-label">Điều kiện:</div><div class="coupon-info">
        - Thời gian ưu đãi: Từ 11/3/2024 - 31/03/2024 <br />
        - Đối tượng áp dụng: Giảm 15% tối đa 10k cho 200k đơn hàng trong tháng 3 <br />
        - Dịch vụ áp dụng: Siêu tốc - đồ ăn, Siêu tốc, 2H - public. <br />
        - Khu vực áp dụng: TP. Hồ Chí Minh, TP. Hà Nội
        </div>
      </div>
    `;
      setModalContent(couponHtml);
      setModalVisible2(true);
    }
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
    },
    {
      title: "Mã hóa đơn ",
      dataIndex: "invoice",
    },
    {
      title: "Thông tin người mua",
      dataIndex: "info_buy",
    },
    {
      title: "Số điện thoại ",
      dataIndex: "phone",
    },
    {
      title: "Tổng giá tiền ",
      dataIndex: "total",
    },
    {
      title: "Địa chỉ chi tiết",
      dataIndex: "address",
    },
    {
      title: "Thời gian đặt đơn",
      dataIndex: "created_at",
    },
    {
      title: "Trạng thái đơn hàng",
      dataIndex: "status_ahamove",
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      render: (_, record) => (
        <Space>
          <CreateOrder id={record.id} status={record.status} cart_id={record.cart_id} />
          <Tooltip title="Chi tiết đơn hàng">
            {record.status !== "new" ? (
              <span
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  opacity: 1,
                }}
                onClick={() => handleDetail(record.cart_id)}
              >
                <EyeTwoTone />
              </span>
            ) : (
              <span
                style={{
                  fontSize: 20,
                  cursor: "not-allowed",
                  opacity: 0.5,
                }}
              >
                <EyeInvisibleTwoTone />
              </span>
            )}
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ marginTop: 16 }}>
      <span style={{ color: "#7790b6", fontSize: "16px" }}>Ahamove</span>
      <RightOutlined style={{ margin: "0 20px", fontSize: 10 }} />
      <span style={{ color: "#7790b6", fontSize: "16px" }}>
        Danh sách đơn hàng
      </span>
      <Divider />
      <Row
        align={"middle"}
        style={{ marginBottom: 16, gap: 20 }}
      >
         <Col span={30}>
            <div className="coupon_item">
                <div className="coupon_icon">
                  <img
                    className="img-fluid"
                    src="https://cdn-img-v2.webbnc.net/media/10201/coupon.png"
                    alt="coupon"
                    width="79"
                    height="70"
                  />
                </div>
                <div className="coupon_body">
                  <div className="coupon_head">
                    <h3 className="coupon_title">NHẬP MÃ: NHANH30PHUT</h3>
                    <div className="coupon_desc">
                       Đồng giá 15k cho đơn hàng,  giảm thẳng 10k/đơn trên Bota
                    </div>
                  </div>
                  <div className="coupon_body_between">
                  <button
                      className={`coupon_copy ${isCopied ? 'disabled' : ''}`}
                      data-ega-coupon="NHANH30PHUT"
                      onClick={() => handleCopy("NHANH30PHUT")}
                    >
                      <span>{isCopied ? "Đã chép" : "Sao chép"}</span>
                    </button>
                    <span
                      className="coupon_info_toggle"
                      data-coupon="NHANH30PHUT"
                      onClick={() => handleConditionClick("NHANH30PHUT", "Thông tin điều kiện", "")}
                    >
                      Điều kiện
                    </span>
                  </div>
                </div>
            </div>
          </Col>
          <Col span={30}>
            <div className="coupon_item">
                <div className="coupon_icon">
                  <img
                    className="img-fluid"
                    src="https://cdn-img-v2.webbnc.net/media/10201/coupon.png"
                    alt="coupon"
                    width="79"
                    height="70"
                  />
                </div>
                <div className="coupon_body">
                  <div className="coupon_head">
                    <h3 className="coupon_title">NHẬP MÃ: BOTATHANG3</h3>
                    <div className="coupon_desc">
                      Giảm 15% tối đa 10k cho 200k đơn hàng trong tháng 3
                    </div>
                  </div>
                  <div className="coupon_body_between">
                  <button
                      className={`coupon_copy ${isCopieds ? 'disabled' : ''}`}
                      data-ega-coupon="BOTATHANG3"
                      onClick={() => handleCopy("BOTATHANG3")}
                    >
                      <span>{isCopieds ? "Đã chép" : "Sao chép"}</span>
                    </button>
                    <span
                      className="coupon_info_toggle"
                      data-coupon="BOTATHANG3"
                      onClick={() => handleConditionClick("BOTATHANG3", "Thông tin điều kiện", "")}
                    >
                      Điều kiện
                    </span>
                  </div>
                </div>
            </div>
          </Col>
          <Modal
              title="NHẬP MÃ: NHANH30PHUT"
              visible={modalVisible1}
              onCancel={() => setModalVisible1(false)}
              className="bota_coupon_modal"
              footer={[
                <Button key="back" onClick={() => setModalVisible1(false)} className="bota_coupon_modal_close">
                  Đóng
                </Button>,
                <Button key="copy" className={`bota_coupon_modal_copy ${isCopied ? 'disabled' : ''}`}
                data-ega-coupon="NHANH30PHUT"
                onClick={() => handleCopy("NHANH30PHUT")}>
                  {isCopied ? "Đã chép" : "Sao chép"}
                </Button>
              ]}
            >
              <div dangerouslySetInnerHTML={{ __html: modalContent }} />
            </Modal>
            <Modal
              title="NHẬP MÃ: BOTATHANG3"
              visible={modalVisible2}
              onCancel={() => setModalVisible2(false)}
              className="bota_coupon_modal"
              footer={[
                <Button key="back" onClick={() => setModalVisible2(false)} className="bota_coupon_modal_close">
                  Đóng
                </Button>,
                <Button key="copy" className={`bota_coupon_modal_copy ${isCopieds ? 'disabled' : ''}`}
                data-ega-coupon="BOTATHANG3"
                onClick={() => handleCopy("BOTATHANG3")}>
                  {isCopieds ? "Đã chép" : "Sao chép"}
                </Button>
              ]}
            >
              <div dangerouslySetInnerHTML={{ __html: modalContent }} />
            </Modal>
      </Row>
      <Row
        align={"middle"}
        style={{ marginBottom: 16 }}
      >
        <Col span={20}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
                <Input
                  placeholder="Tìm kiếm theo mã hoá hoá đơn"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onPressEnter={handleSearch}
                />
              </Col>
              <Col xl lg md sm>
                <Button
                  type="primary"
                  onClick={handleSearch}
                  icon={<SearchOutlined />}
                >
                  Tìm kiếm đơn hàng
                </Button>
              </Col>
              <Col xl lg md sm>
                <Button onClick={handleReset}>Đặt lại</Button>
              </Col>
          </Row>
        </Col>
        <Col span={4} style={{textAlign:"right"}}>
          <Button
            type="primary"
            icon={<SyncOutlined /> }
            onClick={loadDataView}
          >
            Cập nhật đơn hàng
          </Button>
        </Col>
      </Row>
      <Table
        bordered={true}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            const columns = [
              {
                title: "Tên sản phẩm",
                dataIndex: "name",
              },
              {
                title: "Số lượng ",
                dataIndex: "quantity",
                align: "center",
              },
              {
                title: "Giá sản phẩm",
                dataIndex: "price",
                align: "center",
              },
              {
                title: "Hình ảnh",
                dataIndex: "image",
                align: "center",
              },
            ];
            const data = record.items.map((item, index) => {
              const imageUrl = isSSL
                ? item.image.replace("http://upload2.webbnc.vn", "https://cdn-img-v2.webbnc.net")
                : item.image;
              return {
                key: index,
                name: item.name,
                quantity: item.quantity,
                price: item.price.toLocaleString(),
                image: <Image key={index} width={40} src={imageUrl} />,
              };
            });

            return (
              <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
                bordered
              />
            );
          },
        }}
        loading={isLoadOrder || isLoading}
        dataSource={filteredData}
        pagination={{
          showQuickJumper: true,
          showTotal: (total, range) =>
            `Hiển thị kết quả từ ${range[0]} đến ${range[1]} trên tổng ${total}`,
        }}
      />
    </div>
  );
}
