import React from "react";
import { settings } from "../utils/config";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateRouterGrap() {
  return settings?.getCookie("accessTokenGrap") ? (
    <Outlet />
  ) : (
    <Navigate to="/vanchuyen" />
  );
}
