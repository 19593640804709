import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    data: null,
    isLoad: false
}
export const detailAhamove = createAsyncThunk(
    'Ahamove/detailAhamove',
    async (sku) => {
        const response = await http.post(
            `/get-order-detail-ahamove?cart_id=${sku}`
        );
        return response.data.data;
    }
);
const DetailAhamoveOrder = createSlice({
    name: "DetailAhamoveOrder",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(detailAhamove.pending, (state) => {
                state.isLoad = true;
            })
            .addCase(detailAhamove.fulfilled, (state, action) => {
                state.isLoad = false;
                state.data = action.payload;
            })
            .addCase(detailAhamove.rejected, (state, action) => {
                state.isLoad = false;
                // state.err = action.payload.message;

            })
    },
});

export default DetailAhamoveOrder.reducer