import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http, settings } from '../../utils/config';

const initialState = {
    link: null,
    isLoadUrlTikTok: false,
    token: null,
    isLoadToken: false,
    refreshTokenTikTok:null,
    isLoadRefreshToken:false
}
export const getUrlRedirectTikTok = createAsyncThunk(
    'TikTok/getUrlRedirectTikTok',
    async () => {
        const response = await http.get(
            `/login-tiktok`
        );
        return response.data.data;
    }
);
export const getaccessTokenTikTok = createAsyncThunk(
    'TikTok/getaccessTokenTikTok',
    async (code) => {
        const response = await http.get(
            `/get-token-tiktok?auth_code=${code}`
        );
        return response.data.data;
    }
);
export const getRefreshTokenTikTok = createAsyncThunk(
    'TikTok/getRefreshTokenTikTok',
    async (refreshToken) => {
        const response = await http.get(
            `/get-refresh-token-tiktok?refresh_token=${refreshToken}`
        );
        return response.data.data;
    }
);

const getUrlTikTok = createSlice({
    name: "getUrlTikTok",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUrlRedirectTikTok.pending, (state) => {
                state.isLoadUrlTikTok = true;
            })
            .addCase(getUrlRedirectTikTok.fulfilled, (state, action) => {
                state.link = action.payload
                state.isLoadUrlTikTok = false;
            })
            .addCase(getUrlRedirectTikTok.rejected, (state, action) => {
                state.isLoadUrlTikTok = false;
            })
            //Tokenn
            .addCase(getaccessTokenTikTok.pending, (state) => {
                state.isLoadToken = true;
            })
            .addCase(getaccessTokenTikTok.fulfilled, (state, action) => {
                state.token = action.payload
                state.isLoadToken = false;
                settings.setCookie("access_tokenTikTok", action.payload.data.access_token, 7)
                settings.setCookie("refresh_tokenTikTok", action.payload.data.refresh_token, 30)
            })
            .addCase(getaccessTokenTikTok.rejected, (state, action) => {
                state.isLoadToken = false;
            })
            // refresh Token
            .addCase(getRefreshTokenTikTok.pending, (state) => {
                state.isLoadRefreshToken = true;
            })
            .addCase(getRefreshTokenTikTok.fulfilled, (state, action) => {
                state.refreshTokenTikTok = action.payload
                state.isLoadRefreshToken = false;
            })
            .addCase(getRefreshTokenTikTok.rejected, (state, action) => {
                state.isLoadRefreshToken = false;
            })


    },
});

// export const { } = getUrlTikTok.actions

export default getUrlTikTok.reducer