import { AimOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Tooltip, message } from "antd";
import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
export default function Locate(props) {
  const {
    ready,
    value,
    clearSuggestions,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    locationRestriction: {
      north: 23.393395, // Vĩ độ bắc của Việt Nam
      south: 8.180375, // Vĩ độ nam của Việt Nam
      east: 109.464211, // Kinh độ đông của Việt Nam
      west: 102.144546, // Kinh độ tây của Việt Nam
    },
  });
  const options = data.map((address) => ({
    value: address.description,
  }));

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const { formatted_address } = results[0];
      const locate = { lat, lng };
      props.onLocateChange({ locate, formatted_address });
    } catch (error) {
      message.error(error);
    }
  };
  const handleLocate = () => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const results = await getGeocode({
            location: { lat: latitude, lng: longitude },
          });
          const { formatted_address } = results[0];
          setValue(formatted_address);
          const locate = { lat: latitude, lng: longitude };
          props.onLocateChange({ locate, formatted_address });
        } catch (error) {
          message.error(error);
        }
      },
      () => null
    );
  };
  return (
    <>
      <Tooltip title="Xác định vị trí hiện tại của bạn">
        <Button
          icon={<AimOutlined />}
          onClick={handleLocate}
          style={{ borderRadius: 50,marginBottom:8}}
        ></Button>
      </Tooltip>
      <AutoComplete
        style={{ width: "100%" }}
        placeholder="Địa chỉ người gửi"
        options={options}
        value={value}
        onChange={setValue}
        onSelect={handleSelect}
        disabled={!ready}
        allowClear
        onClear={() => props.onLocateChange(null)}
      />
    </>
  );
}
