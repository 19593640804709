import React from "react";
import "./Login.css";
import { Button, Form, Input, Spin, message, Checkbox } from "antd";
import logoBota from "../../assets/img/Frame.png";
import backgroundLogo from "../../assets/img/backgroundLogo.jpg";
import {
  LockOutlined,
  UserOutlined,
  LoadingOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { store } from "../../redux/configStore";
import { useNavigate } from "react-router-dom";
import { checkLogin } from "../../redux/Login/Login";

export default function Login() {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const { isLoadLogin } = useSelector((state) => state.persistedReducer.Login);

  const navigate = useNavigate();
  const onFinish = async (values) => {
    const data = {};
    data.email = values.email;
    data.password = values.password;

    try {
      const resultVnp = await store.dispatch(checkLogin(data));

      if (resultVnp?.payload?.status == 200) {
        navigate("/loadata");
      } else if (resultVnp?.payload?.status == 401) {
        message.error(resultVnp.payload.error);
      } else if (resultVnp?.payload?.status == 404) {
        message.error(resultVnp.payload.error);
      }
    } catch (error) {
      message.error("lỗi dữ liệu");
    }
  };
  return (
    <>
      <div
        className='login'
        style={{
          backgroundImage: `url(${backgroundLogo})`,
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          minHeight: "91vh",
          padding: "70px 0 0",
          display: "flex",
          flexWrap: "wrap",
          alignContent: "space-between",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            flex: "0 0 100%",
            position: "relative",
          }}
        >
          <div
            style={{
              maxWidth: "430px",
              backgroundColor: "#fff",
              margin: "auto",
              borderRadius: "14px",
              textAlign: "center",
              padding: "0",
              position: "relative",
            }}
          >
            <div
              style={{
                padding: "30px 30px 44px",
              }}
            >
              {isLoadLogin && (
                <Spin style={{ top: 155 }} tip='Loading' indicator={antIcon}>
                  <div></div>
                </Spin>
              )}
              <div>
                <a href='#'>
                  <img src={logoBota} alt='' />
                </a>

                <h1
                  style={{
                    color: "#1B1B1B",
                    fontSize: "14px",
                    fontFamily: "'Open Sans', sans-serif",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    textAlign: "center",
                    marginTop: "17px",
                    margin: "0.67em 0",
                  }}
                >
                  Đăng nhập vào hệ thống
                </h1>
              </div>
              <div
                style={{
                  marginTop: "35px",
                }}
              >
                <Form name='normal_login' onFinish={onFinish}>
                  <Form.Item
                    style={{ marginBottom: "15px" }}
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: "Email không được để trống",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        height: "44px",
                        borderRadius: "8px",
                        padding: "0 16px",
                      }}
                      placeholder='Email'
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: "10px" }}
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: "Mật khẩu không được để trống",
                      },
                    ]}
                    errors
                  >
                    <Input.Password
                      style={{
                        height: "44px",
                        borderRadius: "8px",
                        padding: "0 16px",
                      }}
                      type='password'
                      placeholder='Mật khẩu'
                    />
                  </Form.Item>
                  <div
                    style={{
                      fontFamily: "Roboto",
                      display: "flex",
                      alignItems: "center",
                      margin: "auto",
                      justifyContent: "space-between",
                      padding: "0 0 25px 0",
                    }}
                  >
                    <Form.Item
                      style={{ margin: 0 }}
                      name='remember'
                      valuePropName='checked'
                    >
                      <Checkbox style={{ fontWeight: "400", fontSize: "14px" }}>
                        Ghi nhớ đăng nhập
                      </Checkbox>
                    </Form.Item>
                    <div>
                      <a
                        className='forgot-password'
                        style={{ textDecoration: "underline" }}
                        href='#'
                      >
                        Quên mật khẩu ?
                      </a>
                    </div>
                  </div>
                  <Form.Item>
                    <Button
                      style={{
                        background: "#48E064",
                        padding: "9px 36px",
                        height: "auto",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      type='primary'
                      htmlType='submit'
                      className='login-form-button'
                    >
                      Đăng nhập
                    </Button>
                  </Form.Item>
                  <div>
                    <a
                      className='create-acc'
                      style={{
                        textDecoration: "underline",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        color: "black",
                      }}
                      href='https://aff.bota.vn/embed-register?service=/&nxtref=&aff_url=undefined&ref_mkt'
                    >
                      Tạo tài khoản ngay
                    </a>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            color: "white",
            fontSize: "13px",
            fontFamily: "Roboto",
          }}
        >
          <div
            style={{
              padding: "7px 0",
              lineHeight: 1,
              maxWidth: "1170px",
              margin: "auto",
            }}
          >
            <div
              style={{
                padding: "0 15px",
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              <div>
                {" "}
                Copyright © 2023 bota.vn - Giải pháp quản lý và bán hàng đa kênh
                toàn diện
              </div>
              <div>
                <a
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginRight: "30px",
                    fontWeight: "500",
                  }}
                  href='#'
                >
                  Trung tâm trợ giúp
                </a>
                <a
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "500",
                  }}
                  href='#'
                >
                  • Ý kiến phản hồi
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
