import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    createViettelpost: null,
    isLoadingOrder: false,
    hasFetchedData: false,
}
export const orderViettelPost = createAsyncThunk("ViettelPost/orderViettelPost", async (data) => {
    try {
        const response = await http.post("/order-Viettelpost", data);
        return response.data.data;
    } catch (error) {
        throw new Error("Có lỗi xảy ra");
    }
});

const CreateOrderViettelPost = createSlice({
    name: "CreateOrderViettelPost",
    initialState,
    reducers: {
        setHasFetchedData: (state, action) => {
            state.hasFetchedData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(orderViettelPost.pending, (state) => {
                state.isLoadingOrder = true;
            })
            .addCase(orderViettelPost.fulfilled, (state, action) => {
                state.isLoadingOrder = false;
                state.createViettelpost = action.payload;
            })
            .addCase(orderViettelPost.rejected, (state, action) => {
                state.isLoadingOrder = false;
                // state.err = action.payload.message;

            })
    },
});

export const { setHasFetchedData } = CreateOrderViettelPost.actions

export default CreateOrderViettelPost.reducer