import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    listBotav2: null,
    isLoad: false
}
export const getListProductV2 = createAsyncThunk(
    'product/getListProductV2',
    async (idweb) => {
        try {
            const response = await http.get(
                `/List-product-tiktok?idw=${idweb}`
            );
            // console.log(response)
            return response.data.data;
        } catch (error) {
            throw new Error("Có lỗi xảy ra trong quá trình kết nối");
        }
    }
);
const getProductBotaV2 = createSlice({
    name: "getProductBotaV2",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListProductV2.pending, (state) => {
                state.isLoad = true;
            })
            .addCase(getListProductV2.fulfilled, (state, action) => {
                state.listBotav2 = action.payload
                state.isLoad = false;
            })
            .addCase(getListProductV2.rejected, (state, action) => {
                state.isLoad = false;
                // state.eroi = action.error.message;
            })


    },
});

export const { } = getProductBotaV2.actions

export default getProductBotaV2.reducer