import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../utils/config';

const initialState = {
    data: null,
    isLoad: false
}
export const trackingURLAhamove = createAsyncThunk(
    'Ahamove/trackingURLAhamove',
    async (sku) => {
        const response = await http.post(
            `/get-order-tracking-link-ahamove?order_id=${sku}`
        );
        return response.data.data;
    }
);
const TrackingURLAhamove = createSlice({
    name: "TrackingURLAhamove",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(trackingURLAhamove.pending, (state) => {
                state.isLoad = true;
            })
            .addCase(trackingURLAhamove.fulfilled, (state, action) => {
                state.isLoad = false;
                state.data = action.payload;
            })
            .addCase(trackingURLAhamove.rejected, (state, action) => {
                state.isLoad = false;
                // state.err = action.payload.message;

            })
    },
});

export default TrackingURLAhamove.reducer