import React from "react";
import { settings } from "../utils/config";
import { Navigate, Outlet } from "react-router-dom";
export default function PrativeRouterVnp() {
  return settings?.getCookie("access_token_vnp") ? (
    <Outlet />
  ) : (
    <Navigate to='/vanchuyen' />
  );
}
