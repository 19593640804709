import { settings } from "../../../../utils/config";
import { PlusCircleOutlined, DollarOutlined, ReloadOutlined, CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, Form, Select, InputNumber, message, Checkbox, Input, Space } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWarehouse } from "../../../../redux/ViettelPost/getWarehouse";
import TextArea from "antd/es/input/TextArea";
import { getServiceViettelPost } from '../../../../redux/ViettelPost/ServiceViettelPostSlice';
import { ServiceViettelPostXtra } from '../../../../redux/ViettelPost/ServiceViettelPostXtra';
import { CancelOrderViettelPost } from '../../../../redux/ViettelPost/CancelOrderViettelPost';
import { GetPriceViettelPost } from '../../../../redux/ViettelPost/getPriceViettelPost';
import { CreateOrderAllViettelPost } from '../../../../redux/ViettelPost/CreateOrderAllViettelPost';
import ViettelPost from "../../../../assets/img/logo-viettelpost.png";
import "../../../../assets/scss/pages/transport/viettelpost.css";

import { setHasFetchedData } from "../../../../redux/ViettelPost/CreateOrderViettelPost";
import { store } from "../../../../redux/configStore";
import { getListOrder } from "../../../../redux/Giaohangnhanh/listProductOrder";

export default function CreateOrderViettelpost({ id, status }) {
  const [open, setOpen] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [extraServicesData, setExtraServicesData] = useState([]);
  const [selectedExtraServices, setSelectedExtraServices] = useState([]);
  const [selectedExtraServicesCount, setSelectedExtraServicesCount] = useState(0);
  const [showExtraServices, setShowExtraServices] = useState(false);
  const [showOrderService, setShowOrderService] = useState(false);
  const [productWeight, setProductWeight] = useState(0);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [listInventoryId, setListInventoryId] = useState(null);
  const [billingData, setBillingData] = useState(null);
  const [formResetKey, setFormResetKey] = useState(0);
  const [moneyCollectionValue, setMoneyCollectionValue] = useState(0);
  const [productPriceValue, setProductPriceValue] = useState(0);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const [cancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  const loadDataAction = () => {
    const fetchData = async () => {
      try {
          await store.dispatch(getListOrder({ idw : settings.getCookie("user_idw"), page:'vtp'}));
          dispatch(setHasFetchedData(true));
      } catch {
        message.error("Có lỗi xảy ra");
      }
    }
    fetchData();
  };

  const showModal = () => {
    setOpen(true);
    setShowOrderService(false);
  };
  const handleCancel = () => {
    setOpen(false);
    setShowOrderService(false);
  };
  const showCancelOrderModal = () => {
    setCancelOrderModalVisible(true);
  };
  const handleConfirmCancelOrder = async () => {
    try {
      const params = {};
      params.cart_id = id;
      params.token = token;
      params.NOTE = cancelReason;
      const response = await dispatch(CancelOrderViettelPost(params));
      if (response.payload.status === 200) {
        setCancelOrderModalVisible(false);
        setCancelReason(""); 
      }
      setCancelOrderModalVisible(false);
      setCancelReason(""); 
      loadDataAction();
    } catch (error) {
      // Xử lý trường hợp lỗi nếu cần
      message.error("Lỗi khi xác nhận hủy đơn hàng");
    }
  };

  const handleUpdateAfterCancelOrder = async () => {
    try {
      const params = {};
        params.cart_id = id;
        params.token = token;
        params.TYPE = 11;
        params.NOTE = 'Có sự thay đổi';
      const response = await dispatch(CancelOrderViettelPost(params));
      if (response.payload.status === 200) {
        message.success("Thay đổi đơn hàng thành công");
        loadDataAction();
      }
    } catch (error) {
      // Xử lý trường hợp lỗi nếu cần
      message.error("Có lỗi sảy ra khi thay đổi đơn hàng");
    }
  };
  
  const handleCancelCancelOrder = () => {
    setCancelOrderModalVisible(false);
    setCancelReason("");
  };
  const token = settings.getCookie("vietel_post_token");
  const dispatch = useDispatch();
  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          const actionResult = await dispatch(getWarehouse(token));
          const warehousesData = actionResult.payload.data || [];
          setWarehouses(warehousesData); 
          const actionResult_serive = await dispatch(getServiceViettelPost());
          const servicesData = actionResult_serive.payload.data || [];
          setServiceList(servicesData); 
        } catch (error) {
          message.error("Lỗi gọi dữ liệu thất bại");
        }
      };
      fetchData();
    }
  }, [open, dispatch]);

  const { isFetching } = useSelector(
    (state) => state.getWarehouse || {}
  );
  const [selectedValueSerive, setSelectedValueSerive] = useState('');
  const onChangeSerive = async (value) => {
    try {
      if (!value) {
        setExtraServicesData([]); 
      } else {
        const actionResultExtra = await dispatch(ServiceViettelPostXtra(value));
        const extraServicesData = actionResultExtra.payload.data || [];
        setExtraServicesData(extraServicesData);
      }
      setSelectedValueSerive(value);
      const params = {};
        params.product_weight = productWeight;
        params.product_price = productPriceValue;
        params.money_collection = moneyCollectionValue;
        params.order_service_add = "";
        params.order_service = value;
        params.cart_id = id;
        params.list_inventory_id = listInventoryId;
        params.token = token;

      const resPrice = await dispatch(GetPriceViettelPost(params));
      const billingData = resPrice.payload.data;
      setBillingData(billingData);
    } catch (error) {
      console.error('Error fetching extra services data:', error);
      message.error('Lỗi khi lấy dữ liệu dịch vụ cộng thêm');
    }
    setShowExtraServices(!!value && optionsFromExtraServices.length > 0);
  };
  
  const optionsFromExtraServices = extraServicesData.map((service) => ({
    label: service.SERVICE_NAME,
    value: service.SERVICE_CODE,
  }));
  
  const options = [
    ...optionsFromExtraServices,
  ];
  const onChangeCheckbox = async(checkedValues) => {
    setSelectedExtraServices(checkedValues);
    setSelectedExtraServicesCount(checkedValues.length);
    
    const params = {};
        params.product_weight = productWeight;
        params.product_price = productPriceValue;
        params.money_collection = moneyCollectionValue;
        params.order_service_add = checkedValues;
        params.order_service = selectedValueSerive;
        params.cart_id = id;
        params.list_inventory_id = listInventoryId;
        params.token = token;

      const resPrice = await dispatch(GetPriceViettelPost(params));
      const billingData = resPrice.payload.data;
      setBillingData(billingData);
  };
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(amount);
  };
  const calculateDeliveryDays = (kpiHt) => {
    const days = Math.ceil(kpiHt / 24);
    return `${days} ngày`;
  };
  const [form] = Form.useForm();
    //Hàm tính tiền ship
    const feeShip = () => {
      try {
        const fields = ["groupaddress_id", "product_name", "product_quantity", "product_weight", "product_price"];
        const { groupaddress_id, product_name, product_quantity, product_weight, product_price } = form.getFieldsValue(fields);
    
        // Kiểm tra xem các trường đã được nhập đầy đủ hay không
        const isFieldsFilled = fields.every((field) => {
          const value = form.getFieldValue(field);
          return value !== undefined && value !== null && value !== "";
        });
    
        if (!isFieldsFilled) {
          message.error("Vui lòng nhập đầy đủ thông tin ");
          setRequiredFieldsFilled(false);
          return;
        }
    
        setShowOrderService(true);
        setFormResetKey((prevKey) => prevKey + 1);
        setRequiredFieldsFilled(true);
      } catch (error) {
        message.error("Có lỗi xảy ra");
      }
    };
    

  const createOrder = async (values) => {
    try {
      const params = {};
      params.cart_id = id;
        params.list_inventory_id = listInventoryId;
        params.token = token;
        params.PRODUCT_NAME = values.product_name;
        params.PRODUCT_DESCRIPTION = values.product_name;
        params.PRODUCT_QUANTITY = values.product_quantity;
        params.PRODUCT_PRICE = values.product_price;
        params.PRODUCT_WEIGHT = values.product_weight;
        params.PRODUCT_LENGTH = values.product_length;
        params.PRODUCT_WIDTH = values.product_width;
        params.PRODUCT_HEIGHT = values.product_height;
        params.ORDER_PAYMENT = values.oder_payment;
        params.ORDER_SERVICE = values.order_service;
        params.ORDER_SERVICE_ADD = selectedExtraServices;
        // params.ORDER_VOUCHER = values.oder_voucher;
        params.ORDER_NOTE = values.order_note;
        params.MONEY_COLLECTION = values.money_collection;
        params.MONEY_TOTALFEE = billingData.MONEY_TOTAL_FEE;
        params.MONEY_VAS = billingData.MONEY_VAS;
        params.MONEY_FEEINSURANCE = 0;
        params.MONEY_TOTAL_FEE = billingData.MONEY_TOTAL_FEE;
        params.MONEY_FEE = billingData.MONEY_FEE;
        params.MONEY_OTHER_FEE = billingData.MONEY_OTHER_FEE;
        params.MONEY_VAT = billingData.MONEY_VAT;
        params.MONEY_TOTAL = billingData.MONEY_TOTAL;

    const orderViettel = await dispatch(CreateOrderAllViettelPost(params));
    // const order = orderViettel.payload.data;
      if(orderViettel.payload.status == 200){
        message.success("Tạo đơn hàng thành công");
        handleCancel();
        form.resetFields();
        setSelectedExtraServices([]);
        loadDataAction();
      }
    } catch (error) {
      message.error("Tạo đơn hàng thất bại");
    }
  };


  const handleReset = () => {
    // Đặt lại trạng thái hiển thị thông tin vận chuyển
    setShowOrderService(false);
    setFormResetKey((prevKey) => prevKey + 1);
    form.resetFields();
  };
  return (
    <>
      <div className="details_action">
      <Tooltip title="Tạo đơn hàng">
        <Button
          type="primary"
          disabled={
            status !== "new" 
          }
          onClick={showModal}
          icon={<PlusCircleOutlined />}
        ></Button>
      </Tooltip>
        {((status !== "new" && status !== '107' && status !== '201') || status === "new") ? (
          <Tooltip title={status === "new" ? "Đơn hàng mới không thể hủy" : "Hủy đơn hàng"}>
            <Button
              danger
              type="primary"
              onClick={status !== "new" ? showCancelOrderModal : undefined}
              icon={<CloseCircleOutlined />}
              disabled={status === "new"}
            ></Button>
          </Tooltip>
        ) : (
          (status === '107' || status === '201') && (
            <Tooltip title="Xóa đơn hàng">
              <Button
                danger
                type="primary"
                onClick={handleUpdateAfterCancelOrder}
                icon={<DeleteOutlined />}
              ></Button>
            </Tooltip>
          )
        )}
      </div>
      <Modal
       className="popup_viettelpost"
       title={
        <div className="popup_viettelpost_title">
          <span>
            Tạo đơn hàng
          </span>
          <img style={{ width: "103px" }} src={ViettelPost} alt="Viettel Post" />
        </div>
      }
        open={open}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <Form.Provider>
        <Form
           key={formResetKey}
           form={form}
           name="horizontal_viettelpost"
           layout="vertical"
           className="popup_viettelpost_form"
           onFinish={createOrder}
          >
            <Form.Item
              label="Kho xuất hàng"
              name="groupaddress_id"
              rules={[{ required: true, message: "Vui lòng chọn kho xuất hàng" }]}
            >
              <Select
                loading={isFetching}
                options={(warehouses || []).map((warehouse) => ({
                  value: warehouse.groupaddressId,
                  label: warehouse.name + ' - ' + warehouse.address + ' - ' + warehouse.phone,
                }))}
                placeholder="Vui lòng chọn kho xuất hàng"
                onChange={(value) => {
                  setSelectedWarehouseId(value);
                  setListInventoryId(value);
                }}
              />
            </Form.Item>
            <Form.Item
                style={{ width: "100%",marginBottom:16 }}
                name="product_name"
                label="Tên hàng"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên hàng",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Nhập tên hàng"
                />
              </Form.Item>
              <div className="product_goods">
              <Form.Item
                style={{ width: "33.3333333%" }}
                name="product_quantity"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập Số lượng",
                  },
                ]}
                initialValue={1}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={1}
                  maxLength={11}
                  className="product_quantity"
                  placeholder="Số lượng"
                />
              </Form.Item>
              <Form.Item
                style={{ width: "33.3333333%" }}
                name="product_weight"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập Trọng lượng",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={1}
                  maxLength={11}
                  placeholder="Trọng lượng"
                  className="product_weight"
                />
              </Form.Item>
              <Form.Item
                style={{ width: "33.3333333%" }}
                name="product_price"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập Giá trị hàng",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Giá trị hàng"
                  maxLength={11}
                  className="product_price"
                  onChange={(value) => setProductPriceValue(value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                  parser={(value) => value.replace(/(,*)/g, '').replace('.', '')} 
                />
              </Form.Item>
            </div>
            <div className="product_size">
              <label>Kích thước</label>
                  <div className="product_size_form">
                    <Form.Item
                      style={{ width: "100%" }}
                      name="product_length"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        maxLength={11}
                        min={1}
                        placeholder="Dài(cm)"
                      />
                  </Form.Item>
                  <Form.Item
                      style={{ width: "100%" }}
                      name="product_width"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={1}
                        maxLength={11}
                        placeholder="Rộng(cm)"
                      />
                      </Form.Item>
                  <Form.Item
                      style={{ width: "100%" }}
                      name="product_height"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={1}
                        maxLength={11}
                        placeholder="Cao(cm)"
                      />
                    </Form.Item>
              </div>
            </div>
            <Form.Item>
              <Button
                type="primary"
                ghost
                onClick={feeShip}
                icon={<DollarOutlined />}
              >
                Tính phí
              </Button>
            </Form.Item>
              <Form.Item
                  style={{ width: "100%" }}
                  label="Dịch vụ chính"
                  name="order_service"
                  rules={[
                    { required: showOrderService, message: "Vui lòng chọn dịch vụ chính" },
                  ]}
                  hidden={!showOrderService}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Chọn dịch vụ chính"
                    loading={isFetching}
                    options={serviceList.map((service) => ({
                      value: service.SERVICE_CODE, 
                      label: service.SERVICE_NAME,
                    }))}
                    onChange={onChangeSerive}
                  />
                </Form.Item>

              <div className="order_service_add">
                  <Form.Item
                    style={{ width: "100%" }}
                    label={`Dịch vụ cộng thêm (${selectedExtraServicesCount})`}
                    hidden={!showOrderService || !optionsFromExtraServices.length || !selectedValueSerive}
                    name="order_service_add" 
                  >
                    <Checkbox.Group  className="checkbox_service_add" options={options} value={selectedExtraServices} onChange={onChangeCheckbox} />
                  </Form.Item>
              </div>
              {showOrderService && !isNaN(billingData?.MONEY_TOTAL) && !isNaN(billingData?.KPI_HT) && (
                <div className="order_billing">
                  Cước phí: <span className="order_billing_bold">{formatCurrency(billingData?.MONEY_TOTAL)}</span> | Dự kiến giao hàng: <span className="order_billing_bold">{calculateDeliveryDays(billingData?.KPI_HT)}</span>
                </div>
              )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
              }}
            >        
            <Form.Item
                style={{ width: "50%" }}
                name="money_collection"
                label="TIỀN THU HỘ"
                className="money_collection"
                rules={[
                  {
                    required: false,
                    message: "Vui lòng nhập tiền thu hộ  ",
                  },
                  {
                    type: "number",
                    min: 0,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  maxLength={11}
                  placeholder=""
                  onChange={(value) => setMoneyCollectionValue(value)}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                  parser={(value) => value.replace(/(,*)/g, '').replace('.', '')} 
                />
              </Form.Item>

              <Form.Item
                style={{ width: "50%" }}
                label="Loại vận đơn"
                name="oder_payment"
                rules={[
                  { required: true, message: "Vui lòng chọn loại vận đơn" },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Chọn loại vận đơn"
                  options={[
                    {
                      value: 1,
                      label: "Không thu tiền",
                    },
                    {
                      value: 2,
                      label: "Thu hộ tiền cước và tiền hàng",
                    },
                    {
                      value: 3,
                      label: "Thu hộ tiền hàng",
                    },
                    {
                      value: 4,
                      label: "Thu hộ tiền cước",
                    }
                  ]}
                />
              </Form.Item>
            </div>
            <Form.Item 
            label="Ghi chú đơn hàng" 
            name="order_note"
            rules={[
              { required: true, message: "Vui lòng nhập ghi chú đơn hàng" },
            ]}
            >
              <TextArea
                rows={4}
                placeholder="Tối đa 5000 kí tự "
                maxLength={5000}
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<PlusCircleOutlined />}
                  >
                    Tạo đơn
                  </Button>
                  <Button
                  type="primary"
                  htmlType="reset"
                  danger
                  onClick={handleReset}
                  icon={<ReloadOutlined />}
                >
                  Reset
                </Button>
               </Space>
              )}
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
      <Modal
        title="Lý do hủy đơn hàng"
        open={cancelOrderModalVisible}
        onCancel={handleCancelCancelOrder}
        className="popup_cancel_order"
        footer={[
          <Button key="cancel" onClick={handleCancelCancelOrder}>
            Hủy bỏ
          </Button>,
          <Button key="confirm" type="primary" onClick={handleConfirmCancelOrder}>
            Đồng ý
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          placeholder="Nhập lý do hủy đơn hàng"
          maxLength={500}
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </Modal>
    </>
  );
}
